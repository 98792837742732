import { useParams } from "react-router-dom";
import { IProduct } from "../../types";
import { normalizeNameForUrl } from "../../helpers";
import styled from "@emotion/styled";
import { SimilarProductItem } from "../SimilarProductItem";

export const SimilarProducts = ({
  products,
}: {
  products: IProduct[];
  className?: string;
}) => {
  const { categoryId, storeId, harvestId } = useParams();
  return (
    <HorizontalScrollContainer>
      {products?.map((product: IProduct) => {
        const { id, Name, URLPromoCode, Price, RegularPrice, Brand } = product;
        const price = Price !== 0 ? Price : RegularPrice;
        const brand = Brand ? Brand : "";
        let promoCode;

        if (URLPromoCode != null) {
          promoCode = "__promoCode-" + URLPromoCode;
        } else {
          promoCode = "__promoCode-";
        }

        const url = categoryId
          ? `/${storeId}/${harvestId}/ip/${categoryId}/${id}__${brand}__${normalizeNameForUrl(
              Name
            )}}_${price}${promoCode}`
          : `/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
              Name
            )}_${price}${promoCode}`;

        const onClickUrl = categoryId
          ? `/${storeId}/${harvestId}/ip/${categoryId}/${id}__${brand}__${normalizeNameForUrl(
              Name
            )}}_${price}${promoCode}`
          : `/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
              Name
            )}_${price}${promoCode}`;

        return (
          <SimilarProductItem
            key={id}
            product={product}
            url={url}
            onClick={onClickUrl}
          />
        );
      })}
    </HorizontalScrollContainer>
  );
};

const HorizontalScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 15px;
  scrollbar-width: none;
`;
