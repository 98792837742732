import { useEffect, useState } from "react";
import { FaRegTrashCan } from "react-icons/fa6";
import { IProduct } from "../../../../types";
import { useCartHook } from "../../useCartHook";
import { theme } from "../../../../theme";
import { HiOutlineMinusCircle } from "react-icons/hi2";
import { BsPlusCircleFill } from "react-icons/bs";
import styled from "@emotion/styled";
import { useAppContext } from "../../../../context";
import { actions } from "../../../../context/actions";

interface StepperSectionProps {
  product: IProduct;
  isAmount: boolean;
  productInCart: IProduct;
}

export const StepperElement = ({
  product,
  isAmount,
  productInCart,
}: StepperSectionProps) => {
  // const { handleCart } = useCartHook();
  const { dispatch } = useAppContext();
  const [isVisible, setIsVisible] = useState(false);
  let href = window.location.pathname.replace("cart", "");

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleAddToCart = () => {
    dispatch({
      type: actions.ADD_TO_CART,
      payload: {
        id: product.id,
        name: product.Name,
        regularPrice: product.RegularPrice,
        price: product.Price,
        source: "cart",
        href: href,
        promo: product.URLPromoCode,
        brand: product.Brand,
      },
    });
  };

  const handleRemoveFromCart = () => {
    dispatch({
      type: actions.REMOVE_FROM_CART,
      payload: {
        product: {
          id: product.id,
          name: product.Name,
          regularPrice: product.RegularPrice,
          price: product.Price,
          source: "cart",
          href: href,
          promo: product.URLPromoCode,
          brand: product.Brand,
        },
        type: "REMOVE_ONE",
      },
    });
  };

  return (
    <ComponentWrapper isVisible={isVisible}>
      <StepperWrapper>
        <div onClick={handleRemoveFromCart} style={{ marginRight: "20px" }}>
          <HiOutlineMinusCircle size={"35px"} color={theme.samsclub.grey} />
        </div>
        <span>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p
              style={{ fontSize: "14px", fontWeight: 600, marginBottom: "4px" }}
            >
              {isAmount ? productInCart.amount : 0}
            </p>
            <p style={{ fontSize: "10px", color: "grey" }}>in Cart</p>
          </div>
        </span>
        <div onClick={handleAddToCart} style={{ marginLeft: "20px" }}>
          <BsPlusCircleFill size={"28px"} color={theme.samsclub.primary} />
        </div>
      </StepperWrapper>
    </ComponentWrapper>
  );
};

const ComponentWrapper = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  width: 100%;
  justify-content: center;
  height: 50px;
`;

const StepperWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  margin-left: 8px;
  width: 100%;

  span {
    border-radius: 4px;
    color: ${theme.amazon.primary};
    width: 80px;
    padding: 12px;
  }

  p {
    font-size: 30px;
    color: ${theme.amazon.red};
  }
`;
