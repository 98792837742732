import React from 'react';

import { useParams } from 'react-router-dom';
import { useAppContext } from '../../context';
import { actions } from '../../context/actions';
import { useConfiguration } from '../../hooks/useConfiguration';

export const useFiltersMenu = () => {
  const { storeId = '', harvestId = '' } = useParams();

  const {
    value: { modalType, isInlineFilters, searchFilters, productsSorting },
    dispatch,
  } = useAppContext();

  const { FilterVisibility } = useConfiguration({ storeId, harvestId });

  const handleFiltersVisibility = () => {
    if (window.innerWidth < 768) {
      dispatch({
        type: actions.TOGGLE_MODAL,
        payload: { isModal: false },
      });
    } else {
      if (FilterVisibility === 'Stick') {
        dispatch({
          type: actions.TOGGLE_INLINE_FILTERS,
          payload: {
            isInlineFilters: false,
          },
        });
      } else {
        dispatch({
          type: actions.TOGGLE_MODAL,
          payload: { isModal: false },
        });
      }
    }
  };

  const [isOpen, setIsOpen] = React.useState<{ [key: string]: boolean }>({});

  const handleOpen = (name: string) => {
    setIsOpen({ ...isOpen, [name]: !isOpen[name] });
  };

  const handleUpdate = () => {
    const buttons = document.querySelectorAll('.submit-filter');
    buttons.forEach((button: any) => button.click());
  };

  const handleReset = () => {
    dispatch({
      type: actions.REMOVE_SEARCH_FILTER,
    })
  };

  return {
    modalType,
    handleFiltersVisibility,
    isOpen,
    handleOpen,
    handleUpdate,
    handleReset,
    isInlineFilters,
  };
};
