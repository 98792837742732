import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useCartHook } from "./useCartHook";
import { CartEmptySection } from "./cart/emptyCart/CartEmptySection";
import { CartShippingSection } from "./cart/CartShippingSection";
import { useAppContext } from "../../context";
import { useTheme } from "@mui/material";

export const CartRoute = () => {
  const { totalCartValue, checkout, checkoutMessage } = useCartHook();
  const {
    value: { cart },
  } = useAppContext();
  const muiTheme = useTheme();

  const totalItemsInCart = cart.reduce(
    (total: any, item: any) => total + item.amount,
    0
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatPrice = (price: number) => {
    if (isNaN(price)) {
      return { dollars: "0", cents: "00" };
    }
    const formattedPrice = price.toFixed(2);
    const [dollars, cents] = formattedPrice.split(".");
    return { dollars, cents };
  };

  const cartValueNumber =
    typeof totalCartValue === "string"
      ? parseFloat(totalCartValue)
      : totalCartValue;
  const { dollars, cents } = formatPrice(cartValueNumber);

  return (
    <CartComponentWrapper>
      {checkoutMessage ? (
        <CheckoutMessage theme={muiTheme}>Checkout finished</CheckoutMessage>
      ) : totalItemsInCart > 0 ? (
        <>
          <CartShippingSection />
          <CartDetailsContainer theme={muiTheme}>
            <Header>Pickup Cart Details</Header>
            <Row>
              <TextBold>Estimated Subtotal</TextBold>
              <TextBold>${totalCartValue}</TextBold>
            </Row>
            <PickupLocation>
              <TextBold>Pickup Location</TextBold>
              <LocationRow>
                <LocationInfo>
                  <LocationText>Beverly Doheny</LocationText>
                  <LocationText>9040 Beverly Blvd</LocationText>
                  <LocationText>West HollyWood, CA 90048</LocationText>
                </LocationInfo>
                <ChangeStore>Change Store</ChangeStore>
              </LocationRow>
            </PickupLocation>
          </CartDetailsContainer>
          <Spacer />
        </>
      ) : (
        <CartEmptySection />
      )}
      <FixedBottomDiv>
        <TotalContainer>
          <TotalText>Estimated Total:</TotalText>
          <PriceContainer>
            <Sup>$</Sup>
            {dollars}
            <Sup>{cents}</Sup>
          </PriceContainer>
        </TotalContainer>
        <CheckoutContainer>
          <CheckoutButton onClick={() => checkout("checkout", cart)}>
            <ButtonText>Check Out</ButtonText>
          </CheckoutButton>
        </CheckoutContainer>
      </FixedBottomDiv>
    </CartComponentWrapper>
  );
};

const CartComponentWrapper = styled.div`
  padding-top: 10px;
`;

const CheckoutMessage = styled.div<{ theme: any }>`
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: ${({ theme }) => theme.palette.font};
  margin-top: 100px;
`;

const CartDetailsContainer = styled.div<{ theme: any }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.backgroundElement};
  color: ${({ theme }) => theme.palette.font};
  padding: 12px 12px;
  margin-bottom: 40px;
`;

const Header = styled.h1`
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TextBold = styled.p`
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 12px;
`;

const GreyText = styled.p<{ marginTop?: number; marginBottom?: number }>`
  font-size: 12px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 8}px;
  margin-top: ${({ marginTop }) => marginTop || 0}px;
  color: grey;
  letter-spacing: 0.35px;
`;

const PickupLocation = styled.div`
  margin-top: 16px;
`;

const LocationRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LocationInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const LocationText = styled.p`
  font-size: 14px;
`;

const ChangeStore = styled.p`
  font-size: 18px;
  font-weight: 800;
  color: #2875c1;
`;

const Spacer = styled.div`
  height: 88px;
`;

const FixedBottomDiv = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 60px;
  width: 100%;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  height: 70px;
  padding: 0 12px;
  background-color: ${({ theme }: any) => theme.palette.backgroundElement};
  color: ${({ theme }: any) => theme.palette.font};
`;

const PriceContainer = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
`;

const Sup = styled.sup`
  font-size: 0.6em;
`;

const TotalContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

const TotalText = styled.p`
  font-weight: 600;
  margin-bottom: 10px;
`;

const CheckoutContainer = styled.div`
  display: flex;
  flex: 3;
  align-items: center;
  justify-content: flex-end;
`;

const CheckoutButton = styled.button`
  border: none;
  border-radius: 24px;
  padding: 10px 44px;
  background-color: ${({ theme }: any) => theme.palette.steelblue};
  color: ${({ theme }: any) => theme.palette.white};
`;

const ButtonText = styled.p`
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;
