import React from "react";
import styled from "@emotion/styled";
import { IProduct } from "../../types";
import { imgSrcByEnv } from "../../../config/imgSrcByEnv";
import { contentSquarePageEvent } from "../../helpers";

export const PdpGallery = ({ product }: { product: IProduct }) => {
  const gallery = product?.Gallery;
  const video = product?.VideoGallery;
  const thumbnailsRef = React.useRef<HTMLDivElement>(null);
  const enlargedGalleryRef = React.useRef<HTMLDivElement>(null);
  const [currentImageIndex, setCurrentImageIndex] = React.useState<number>(0);
  const [isImageEnlarged, setIsImageEnlarged] = React.useState(false);
  const [containerDimensions, setContainerDimensions] = React.useState({
    width: "100%",
    height: "auto",
  });

  let arr = [""];

  gallery && gallery.map(({ image: { fullpath } }) => {
    arr.push(fullpath);
  });

  video && video.map(({ Video: { data, poster }, VideoPosition }) => {
    arr.splice( Number(VideoPosition), 0, data.fullpath + '^^^' + poster.fullpath);
  });
  arr.shift();

  React.useEffect(() => {
    setCurrentImageIndex(0);
  }, [gallery]);

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index);
    setIsImageEnlarged(true);
    setContainerDimensions({ width: "100%", height: "50vh" });
    contentSquarePageEvent("galleryImageChange");
    centerImage(index, false);
  };

  const handleEnlargedImageClick = () => {
    setIsImageEnlarged(false);
    setContainerDimensions({ width: "100%", height: "auto" });
  };

  const handleCloseClick = () => {
    setIsImageEnlarged(false);
    setContainerDimensions({ width: "100%", height: "auto" });
  };

  const centerImage = (index: number, smooth: boolean = true) => {
    const container = enlargedGalleryRef.current;
    if (container) {
      const containerWidth = container.clientWidth;
      const imageWidth =
        container.querySelector("img")?.clientWidth || containerWidth;
      const scrollLeft =
        index * (imageWidth + 16) - containerWidth / 2 + imageWidth / 2;
      container.scrollTo({
        left: scrollLeft,
        behavior: smooth ? "smooth" : "auto",
      });
    }
  };

  const debounce = (func: Function, wait: number) => {
    let timeout: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  const handleScroll = debounce(() => {
    const container = enlargedGalleryRef.current;
    if (container) {
      const containerWidth = container.clientWidth;
      const imageWidth =
        container.querySelector("img")?.clientWidth || containerWidth;
      const scrollLeft = container.scrollLeft;
      const index = Math.round(scrollLeft / (imageWidth + 16));
      setCurrentImageIndex(index);
      centerImage(index);
    }
  }, 150);

  React.useEffect(() => {
    if (isImageEnlarged) {
      centerImage(currentImageIndex, false);
    }
  }, [isImageEnlarged, currentImageIndex]);

  return (
    <GalleryStyled
      isImageEnlarged={isImageEnlarged}
      containerDimensions={containerDimensions}
    >
      {!isImageEnlarged ? (
        <ThumbnailsContainer>
          <Thumbnails ref={thumbnailsRef}>
            {arr && arr.map((elem, index) => {
              let videoPaths = elem.split('^^^');
              const fullpath = !elem.includes("Videos") ? elem : videoPaths[1]; //thumbnails
              return (
                <Image
                  key={index}
                  className={currentImageIndex === index ? "current" : ""}
                  alt="product"
                  src={`${imgSrcByEnv()}${fullpath}`}
                  onClick={() => handleImageClick(index)}
                  isCurrent={currentImageIndex === index}
                />
              );
            })}
          </Thumbnails>
        </ThumbnailsContainer>
      ) : (
        <EnlargedGalleryWrapper>
          <CloseButton onClick={handleCloseClick}>×</CloseButton>
          <EnlargedGalleryContainer
            ref={enlargedGalleryRef}
            onScroll={handleScroll}
          >
              {arr && arr.map((elem, index) => {
                let videoPaths = elem.split('^^^');
                const fullpath = !elem.includes("Videos") ? elem : videoPaths[0]; //big images
                return (
                  !elem.includes("Videos") ?
                    <EnlargedImage
                      key={index}
                      src={`${imgSrcByEnv()}${fullpath}`}
                      alt="product"
                      onClick={handleEnlargedImageClick}
                      isCurrent={currentImageIndex === index}
                    /> :
                    <EnlargedVideo
                      key={index}
                      src={`${imgSrcByEnv()}${fullpath}`}
                      // onClick={handleEnlargedImageClick}
                      isCurrent={currentImageIndex === index}
                      controls autoPlay muted loop
                    />
                )
              })}
          </EnlargedGalleryContainer>
        </EnlargedGalleryWrapper>
      )}
    </GalleryStyled>
  );
};

const GalleryStyled = styled.div<{
  isImageEnlarged: boolean;
  containerDimensions: { width: string; height: string };
}>`
  grid-column: 1 / span 2;
  display: flex;
  padding-top: 80px;
  margin-bottom: 25px;
  gap: 24px;
  width: ${(props) => props.containerDimensions.width};
  height: ${(props) => props.containerDimensions.height};
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding-top: 0;
  }
`;

const ThumbnailsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: auto;
`;

const Thumbnails = styled.div`
  display: flex;
  overflow-x: scroll;
  -webkit-tap-highlight-color: transparent;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  gap: 16px;
  flex-direction: row;
  align-items: flex-start;
`;

const Image = styled.img<{ isCurrent: boolean }>`
  display: block;
  object-fit: contain;
  cursor: pointer;
  height: 168px;
  width: 168px;
  display: block;
`;

const EnlargedGalleryWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 50vh;
`;

const EnlargedGalleryContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  -webkit-tap-highlight-color: transparent;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  width: 100%;
  height: 100%;
  gap: 16px;
  flex-direction: row;
  align-items: center;
  scroll-snap-type: x mandatory;
`;

const EnlargedImage = styled.img<{ isCurrent: boolean }>`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
  height: 50vh;
  width: auto;
  scroll-snap-align: center;
`;
const EnlargedVideo = styled.video<{ isCurrent: boolean }>`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
  height: auto;
  width: auto;
  scroll-snap-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: -10px;
  right: 0;
  border: none;
  color: #000000;
  background: transparent;
  font-size: 45px;
  cursor: pointer;
  z-index: 10;
  border-radius: 50%;
`;
