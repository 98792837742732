
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { theme } from '../../theme';

export const LinkStyled = styled(Link)`
  text-decoration: none;
`;

export const ProductContainer = styled.span<{ theme: any }>`
  display: inline-block;
  background: ${({ theme }) => theme.palette.backgroundElement};
  color: ${({ theme }) => theme.palette.font};
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 !important;
  padding: 10px;
  border-radius: 10px;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 5px;
  }

  gap: 10px;
  @media (max-width: 768px) {
    text-align: left;
  }

  & > div > img {
    height: 80px;
    width: 120px;
    object-fit: contain;
    &:hover {
      cursor: pointer;
    }
  }
  & > div > div{
    margin-top: 3px;
  }

  & > div:first-of-type {
    max-width: 140px;
  }
`;

export const ProductHeader = styled.h1`
  width: 144px;
  height: auto;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
`;

export const DescriptionContainer = styled.div`
  & > p:first-of-type {
    margin-top: 5px;
  }
  & > p:nth-of-type(2) {
    margin: 12px 0 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }
`;
