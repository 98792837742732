import { useState } from "react";
import { IProduct } from "../../../../types";
import { IoIosCloseCircleOutline } from "react-icons/io";
import styled from "@emotion/styled";
import { imgSrcByEnv } from "../../../../../config/imgSrcByEnv";
import { StepperElement } from "./StepperElement";
import { useTheme } from "@mui/material";
import { useAppContext } from "../../../../context";
import { actions } from "../../../../context/actions";
import { useNavigate, useParams } from "react-router-dom";

export const CartProductCard = ({
  product,
  isLast,
}: {
  product: IProduct;
  isLast: boolean;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { storeId = "", harvestId = "" } = useParams();
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
  const discountPriceDifference = product.RegularPrice - product.Price;
  const {
    value: { cart },
    dispatch,
  } = useAppContext();
  const totalItemsInCart = cart.reduce(
    (total: any, item: any) => total + item.amount,
    0
  );
  let href = window.location.pathname.replace("cart", "");

  const formatPrice = (price: number) => {
    const [dollars, cents] = price.toFixed(2).split(".");
    return { dollars, cents };
  };
  const normalizeNameForUrl = (name: string | null) => {
    if (!name) return "";
    return name
      .normalize("NFD")
      .replaceAll(" ", "_")
      .replace(/[^a-zA-Z0-9_ ]/g, "");
  };

  const navigateToProduct = ({
    id,
    name,
    brand,
    price,
    promo,
    amount,
  }: {
    id: string;
    name: string;
    brand: string;
    price: number;
    promo: string;
    amount: number;
  }) =>
    navigate(
      `/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
        name
      )}_${price}__promoCode-${promo}_cartadd${amount}`
    );

  const handleDeleteItemFromCart = () => {
    dispatch({
      type: actions.REMOVE_FROM_CART,
      payload: {
        product: {
          id: product.id,
          name: product.Name,
          regularPrice: product.RegularPrice,
          price: product.Price,
          source: "cart",
          href: href,
          promo: product.URLPromoCode,
          brand: product.Brand,
        },
        type: "REMOVE_ALL",
      },
    });
  };

  const muiTheme = useTheme();
  const promo = product.URLPromoCode
    ? "__promoCode-" + product.URLPromoCode
    : "__promoCode-";
  return (
    <StyledComponentWrapper isLoading={isLoading} theme={muiTheme}>
      {isLoading && <SpinnerWrapper></SpinnerWrapper>}

      {totalItemsInCart > 0 ? (
        <>
          {product?.Gallery && product.Gallery.length > 0 && (
            <div>
              <FlexEndContainer>
                <IoIosCloseCircleOutline
                  size={24}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteItemFromCart();
                  }}
                />
              </FlexEndContainer>

              <Row>
                <Flex1>
                  <ProductImage
                    src={`${imgSrcByEnv()}${
                      product.Gallery[0]?.image?.fullpath
                    }`}
                    alt="product"
                    onClick={() =>
                      navigateToProduct({
                        id: product.id,
                        name: product.Name,
                        brand: product.Brand,
                        price: product.Price,
                        promo: product.URLPromoCode,
                        amount: product.amount,
                      })
                    }
                  />
                </Flex1>
                <Column flex={3} padding="0px 12px 12px 12px">
                  <PriceContainer>
                    <PriceRow>
                      {product.Price !== product.RegularPrice &&
                        product.Price > 0 && (
                          <CurrentPrice>
                            <sup>$</sup>
                            {formatPrice(product.Price).dollars}
                            <sup>.{formatPrice(product.Price).cents}</sup>
                          </CurrentPrice>
                        )}
                      <OldPrice
                        isDiscounted={
                          product.Price !== product.RegularPrice &&
                          product.Price > 0
                        }
                      >
                        <sup>$</sup>
                        {formatPrice(product.RegularPrice).dollars}
                        <sup>.{formatPrice(product.RegularPrice).cents}</sup>
                      </OldPrice>
                    </PriceRow>

                    {product.Price !== product.RegularPrice &&
                      product.Price > 0 && (
                        <DiscountText>
                          You save ${discountPriceDifference.toFixed(2)}
                        </DiscountText>
                      )}
                  </PriceContainer>

                  <CardProductDetailsParagraph
                    onClick={() =>
                      navigateToProduct({
                        id: product.id,
                        name: product.Name,
                        brand: product.Brand,
                        price: product.Price,
                        promo: product.URLPromoCode,
                        amount: product.amount,
                      })
                    }
                  >
                    {product.Name}
                  </CardProductDetailsParagraph>

                  <AttributesContainer>
                    {product?.Attribute &&
                      product.Attribute.length > 0 &&
                      product.Attribute.map((atr) => (
                        <CardProductDetailsParagraph key={atr?.AttrName}>
                          {atr.AttrName} {atr.AttrValue}
                        </CardProductDetailsParagraph>
                      ))}
                  </AttributesContainer>

                  <StepperElement
                    product={product}
                    isAmount={true}
                    productInCart={product}
                  />
                </Column>
              </Row>
            </div>
          )}
        </>
      ) : (
        <p>empty</p>
      )}
    </StyledComponentWrapper>
  );
};

const StyledComponentWrapper = styled.div<{ isLoading: boolean; theme: any }>`
  position: relative;
  border: 1px solid grey;
  margin: 0px 12px 10px 12px;
  border-radius: 12px;
  padding: 8px;
  background-color: ${({ theme }) => theme.palette.backgroundElement};
  color: ${({ theme }) => theme.palette.font};
  cursor: pointer;

  ${({ isLoading }) =>
    isLoading &&
    `
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7); /* Adjust the transparency as needed */
      z-index: 1; /* Ensure the overlay is above other content */
      pointer-events: none; /* Allow interaction with elements beneath the overlay */
    }
  `};
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
`;

const FlexEndContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Flex1 = styled.div`
  flex: 1;
`;

const Column = styled.div<{ flex: number; padding?: string }>`
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex};
  padding: ${({ padding }) => padding || "0"};
`;

const ProductImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-right: 12px;
`;

const OldPrice = styled.p<{ isDiscounted: boolean }>`
  font-size: 24px;
  display: flex;
  align-items: flex-start;
  font-weight: 600;
  text-decoration: ${({ isDiscounted }) =>
    isDiscounted ? "line-through" : "none"};

  sup {
    font-size: 0.6em;
    margin-right: 2px;
  }
`;

const CurrentPrice = styled.p`
  font-size: 24px;
  display: flex;
  align-items: flex-start;
  font-weight: 600;

  sup {
    font-size: 0.6em;
    margin-right: 2px;
  }
`;

const DiscountText = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: red;
`;

const CardProductDetailsParagraph = styled.div<{ color?: string }>`
  font-size: 14px;
  font-weight: 500;
  width: 200px;
  font-weight: 600;
  color: ${({ color }) => color || "inherit"};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;

  span {
    font-size: 12px;
  }
`;

const AttributesContainer = styled.div`
  margin-top: 12px;
`;
