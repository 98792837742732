import React, { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { FormControlLabel, useTheme } from "@mui/material";
import styled from "@emotion/styled";
import { theme } from "../../theme";
import { useConfiguration } from "../../hooks/useConfiguration";
import { useParams } from "react-router-dom";
import { PriceDisplay } from "./PriceDisplay";

export const ShippingMethod = ({
  product,
  shipmentMethods,
  shippingMethodSelected,
  setShippingMethodSelected,
}: any) => {
  const [selectedMethod, setSelectedMethod] = useState(shippingMethodSelected);
  const muiTheme = useTheme();
  useEffect(() => {
    setSelectedMethod(shippingMethodSelected);
  }, [shippingMethodSelected]);

  const handleSelectionChange = (event: any) => {
    const newSelection = event.target.value;
    setSelectedMethod(newSelection);
    setShippingMethodSelected(newSelection);
  };

  const { storeId = "", harvestId = "" } = useParams();
  const { Currency } = useConfiguration({
    storeId,
    harvestId,
  });

  const currency = Currency ? Currency : "$";
  const pricePerUnit =
    product.PricePerUnit && product.PricePerUnit !== "0"
      ? " (" + product.PricePerUnit + ")"
      : "";

  const renderPrice = (product: any) => (
    <PriceDisplay
      currency={currency}
      price={product.Price}
      regularPrice={product.RegularPrice}
      pricePerUnit={pricePerUnit}
      showOldPrice={false}
    />
  );

  return (
    <ShippingMethodsContainer theme={muiTheme}>
      <StyledRadioGroup value={selectedMethod} onChange={handleSelectionChange}>
        {shipmentMethods.map((method: any, key: any) => (
          <StyledFormControlLabel
            theme={muiTheme}
            key={key}
            value={method.name.toLowerCase()}
            control={<StyledRadio />}
            label={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span>{method.name}</span>
                {renderPrice(product)}
              </div>
            }
          />
        ))}
      </StyledRadioGroup>
    </ShippingMethodsContainer>
  );
};
const ShippingMethodsContainer = styled.div<{ theme: any }>`
  background: ${({ theme }) => theme.palette.backgroundElement};
  padding: 30px 10px;
`;

const StyledRadioGroup = styled(RadioGroup)`
  & .MuiFormControlLabel-root {
    margin: 10px 0;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)<{ theme: any }>`
  & .MuiFormControlLabel-label {
    color: ${({ theme }) => theme.palette.font};
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

const StyledRadio = styled(Radio)`
  & .MuiSvgIcon-root {
    font-size: 24px;
  }

  &.MuiRadio-root {
    color: ${theme.kroger.grey};
  }

  &.Mui-checked {
    color: ${theme.kroger.primary};
  }
`;
