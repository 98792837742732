import { theme } from "../../theme/index";
import { useState } from "react";
import styled from "@emotion/styled";

export const HomeProductsSliderButtons = ({ labels }: { labels: string[] }) => {
  const [activeButton, setActiveButton] = useState(labels[0]);
  const buttonWidth = 98 / labels.length;

  return (
    <Container>
      <ButtonContainer>
        {labels.map((label: string) => (
          <Button
            key={label}
            onClick={() => setActiveButton(label)}
            isActive={activeButton === label}
            width={buttonWidth}
          >
            <p>{label}</p>
          </Button>
        ))}
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  background-color: ${theme.kroger.greyLight};
  padding: 3px 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  gap: 1px;
`;

const Button = styled.button<{ width: any; isActive: boolean }>`
  width: ${({ width }) => width}%;
  background-color: ${({ isActive }) =>
    isActive ? theme.kroger.white : theme.kroger.greyLight};
  color: ${theme.kroger.black};
  border: none;
  padding: 8px 0;
  border-radius: 4px;

  p {
    font-size: 14px;
    font-weight: ${({ isActive }) => (isActive ? 600 : 500)};
  }
`;
