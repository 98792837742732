import { useCallback, useEffect, useState } from "react";
import { BottomNavigationButton } from "./BottomNavigationButton";
import { AiOutlineHome } from "react-icons/ai";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";

interface NavigationProps {
  storeId: string;
  harvestId: string;
}

export const bottomNavigationBarButtonData = [
  { iconName: "home", label: "Home" },
  { iconName: "ads", label: "Weekly Ads" },
  { iconName: "savings", label: "Savings" },
  { iconName: "shop", label: "Shop" },
  { iconName: "cart", label: "Cart" },
];

export const BottomNavigationBar = ({
  storeId,
  harvestId,
}: NavigationProps) => {
  const baseUrl = `/${storeId}/${harvestId}`;
  const navigate = useNavigate();
  const location = useLocation();

  const getActiveIcon = useCallback(() => {
    const path = location.pathname;
    if (path.endsWith("/cart")) {
      return "cart";
    }
    const pathParts = path.split("/").filter(Boolean);
    return pathParts.length === 2 ? "home" : null;
  }, [location.pathname]);

  const [clickedIcon, setClickedIcon] = useState<string | null>(getActiveIcon);

  const handleNavigation = useCallback(
    (iconName: string) => {
      const navigateMap: { [key: string]: string } = {
        home: baseUrl,
        cart: `${baseUrl}/cart`,
      };

      return () => {
        if (clickedIcon !== iconName) {
          setClickedIcon(
            iconName === "home" || iconName === "cart" ? iconName : null
          );
          navigate(navigateMap[iconName]);
        }
      };
    },
    [baseUrl, clickedIcon, navigate]
  );

  useEffect(() => {
    setClickedIcon(getActiveIcon());
  }, [location.pathname, getActiveIcon]);

  return (
    <BottomNavigationBarWrapper>
      <ItemsContainer>
        {bottomNavigationBarButtonData.map((button) => (
          <BottomNavigationButton
            key={button.label}
            iconName={button.iconName}
            label={button.label}
            active={clickedIcon === button.iconName}
            onClick={handleNavigation(button.iconName)}
            icon={<AiOutlineHome fontSize={24} />}
          />
        ))}
      </ItemsContainer>
    </BottomNavigationBarWrapper>
  );
};

const BottomNavigationBarWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 1000;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }: any) => theme.palette.backgroundElement};
`;

const ItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
