import React from "react";
import Stack from "@mui/material/Stack";
import { Rating } from "@mui/material";
import styled from "@emotion/styled";
import { theme } from "../../theme";

export const RatingSection = ({ product, onRatingAmountClick }: any) => {
  return (
    <RatingContainer>
      {product.RRAmount !== 0 ? (
        <div className="rating">
          {product.RROverall && (
            <Stack spacing={1}>
              <StyledRating
                name="half-rating-read"
                defaultValue={product.RROverall}
                precision={0.5}
                readOnly
              />
            </Stack>
          )}
          {product.RRAmount && (
            <span onClick={onRatingAmountClick}>({product.RRAmount})</span>
          )}
        </div>
      ) : (
        <div className="rating">
          {
            <Stack spacing={1}>
              <StyledRating
                name="half-rating-read"
                defaultValue={0}
                precision={0.5}
                readOnly
              />
            </Stack>
          }
          <span>0</span>
        </div>
      )}
    </RatingContainer>
  );
};

const StyledRating = styled(Rating)({
  "& .MuiRating-iconEmpty": {
    color: `${theme.kroger.grey}`,
  },
  "& .MuiRating-iconFilled": {
    color: `${theme.kroger.primary}`,
  },
});

const RatingContainer = styled.div`
  .rating {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    & > span {
      margin-left: 5px;
      font-size: 16px;
    }
  }
`;
