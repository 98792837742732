import React from 'react';
import styled from '@emotion/styled';
import { ModalTypes } from '../Modals/types';
import { theme } from '../../theme';

export const FiltersMenuStyled = styled.div<{
  modalType: ModalTypes;
  isInlineFilters: boolean;
  theme: any;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ modalType }) =>
    modalType === ModalTypes.filtersMenu ? '100vh' : 'auto'};
  width: ${({ modalType }) =>
    modalType === ModalTypes.filtersMenu ? '40vw' : 'auto'};
  background-color: ${({theme}) => theme.palette.bgFilter};
  color: ${({theme}) => theme.palette.font};
  overflow-y: auto;
  @media (max-width: 767px) {
    width: auto;
  }
  min-height: ${({ isInlineFilters }) => (isInlineFilters ? 'auto' : '90vh')};

  .filter-bar{
    padding: 16px 10px;
    background: ${({theme})=> theme.palette.bgFilterElement};
    border-bottom: 1px solid ${theme.kroger.grey};
    span{
      font-size: 14px;
    }
  }

  .filters {
    flex: 1;
    .filter {
      background: ${({theme})=> theme.palette.bgFilterElement};
      position: relative;
      z-index: 1;
      display: flex;
      border-bottom: 1px solid ${theme.kroger.grey};
      justify-content: space-between;
      align-items: center;
      padding: 16px 22px;
      span {
        letter-spacing: 0em;
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        cursor: pointer;
      }
      svg {
        font-size: 20px;
        cursor: pointer;
      }
    }
  }

  .filter-header {
    position: sticky;
    background: ${({theme})=> theme.palette.bgfilterHeader};
    color: ${theme.kroger.white};
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid #000;
    padding: 24px;
    & > span:first-of-type {
      position: absolute;
      left: 24px;
      font-size: 18px;
      cursor: pointer;
    }
    & > span:nth-of-type(2) {
      margin: 0 auto;
      font-family: 'Montserrat';
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0em;
    }
    & > svg {
      font-size: 12px;
      cursor: pointer;
    }
  }

  .filter-footer {
    position: sticky;
    z-index: 2;
    position: ${({ isInlineFilters }) =>
      isInlineFilters ? 'initial' : 'sticky'};
    background: ${({theme})=> theme.palette.bgFilterElement};
    border-top: 1px solid ${theme.kroger.grey};
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    padding: 10px 0 2em;
    & > button {
      font-size: 18px;
      font-weight: 700;
      padding: 8px;
      width: 100%;
      border-radius: 40px;
      border: none;
      cursor: pointer;
    }
    .reset-button{
      border: 2px solid ${theme.kroger.primary};
      color: ${theme.kroger.primary};
      background: transparent;
    }
    .result-button{
      color: ${theme.kroger.white};
      background-color: ${theme.kroger.primary};
    }
  }
`;
