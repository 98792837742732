import { theme } from "../../../theme";
import { useAppContext } from "../../../context";
import { IProduct } from "../../../types";
import { CartProductCard } from "./productCard/CartProductCard";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material";

export const CartShippingSection = () => {
  const {
    value: { cart },
  } = useAppContext();
  const muiTheme = useTheme();

  return (
    <ComponentWrapper theme={muiTheme}>
      {cart.map((product: IProduct, index: number) => (
        <CartProductCard
          key={product.id}
          product={product}
          isLast={index === cart.length - 1}
        />
      ))}
    </ComponentWrapper>
  );
};

const ComponentWrapper = styled.div<{ theme: any }>`
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.backgroundElement};
`;
