import React from "react";
import styled from "@emotion/styled";
import { theme } from "../../theme";

interface PriceDisplayProps {
  currency: string;
  price: number;
  regularPrice?: number;
  pricePerUnit?: string;
  showOldPrice?: boolean;
}

export const PriceDisplay: React.FC<PriceDisplayProps> = ({
  currency,
  price,
  regularPrice,
  pricePerUnit,
  showOldPrice = true,
}) => {
  const mainPrice = price !== 0 ? price : regularPrice || price;
  const isDiscounted = price !== 0 && regularPrice !== undefined;

  return (
    <PriceStyled isDiscounted={isDiscounted}>
      <div className="price-container">
        <div className="main-price">
          <span className="currency">{currency}</span>
          <span className="regular-price">{Math.floor(mainPrice)}</span>
          <sup className="fraction">
            {(mainPrice % 1).toFixed(2).split(".")[1]}
          </sup>
        </div>
        {isDiscounted && showOldPrice && (
          <div className="old-price">
            <span className="currency">{currency}</span>
            <span className="regular-price">{Math.floor(regularPrice!)}</span>
            <sup className="fraction">
              {(regularPrice! % 1).toFixed(2).split(".")[1]}
            </sup>
          </div>
        )}
      </div>
      {pricePerUnit && <span className="price-per-unit">{pricePerUnit}</span>}
    </PriceStyled>
  );
};

const PriceStyled = styled.div<{ isDiscounted: boolean }>`
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 28px;
  line-height: 22px;

  .price-container {
    display: flex;
    align-items: baseline;
  }

  .main-price {
    display: flex;
    align-items: baseline;
    background-color: ${({ isDiscounted }) =>
      isDiscounted ? `${theme.kroger.yellow}` : "transparent"};
    color: ${({ isDiscounted }) =>
      isDiscounted ? `${theme.kroger.black}` : "inherit"};
    padding: ${({ isDiscounted }) => (isDiscounted ? "5px 10px" : "0")};
    border-radius: 5px;
    margin-right: 10px;
  }

  .old-price {
    display: flex;
    align-items: baseline;
    text-decoration: line-through;
  }

  .currency {
    font-size: 0.7em;
    margin-right: 2px;
    vertical-align: top;
    position: relative;
    bottom: 16px;
  }

  .regular-price {
    font-size: 40px;
    line-height: 1;
  }

  .fraction {
    font-size: 0.7em;
    vertical-align: top;
    position: relative;
    bottom: 16px;
  }

  .old-price .currency {
    font-size: 0.4em;
    position: relative;
    bottom: 24px;
  }

  .old-price .regular-price {
    font-size: 25px;
    position: relative;
    bottom: 14px;
    line-height: 1;
  }

  .old-price .fraction {
    font-size: 0.4em;
    position: relative;
    bottom: 24px;
  }

  .price-per-unit {
    margin-left: 10px;
    font-size: 14px;
    color: #777;
  }
`;
