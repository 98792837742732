import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IProduct } from "../../types";
import { normalizeNameForUrl } from "../../helpers";
import { Tooltip, tooltipClasses } from "@mui/material";
import { styled as MuiStyled } from "@mui/material/styles";

export const VariantSelector = ({ product }: { product: IProduct }) => {
  const navigate = useNavigate();
  const { storeId = "", categoryId, harvestId = "" } = useParams();

  return (
    <>
      {product?.Attribute[0].AttrName &&
        product?.Attribute[0].AttrValue !== "value" && (
          <div className="variants-container">
            {product?.Attribute.map((attribute, index) => {
              return (
                product?.Variants &&
                product?.Variants.length > 0 && (
                  <div className="variants-inner-container" key={index}>
                    <p>
                      <span>
                        {attribute.AttrName} - {attribute.AttrValue}
                      </span>
                    </p>
                    <div className="variants">
                      {product?.Variants?.map(
                        (variant: IProduct, variant_index: number) => {
                          if (
                            variant?.Attribute[index]?.AttrName &&
                            variant?.Attribute[index]?.AttrValue !== "value" &&
                            product?.Attribute[index].AttrValue !==
                              variant?.Attribute[index]?.AttrValue
                          ) {
                            const brand = variant?.Brand ? variant?.Brand : "";
                            return (
                              <div
                                className={
                                  product?.id === variant?.id ? "active" : ""
                                }
                                key={variant_index}
                                onClick={() =>
                                  navigate(
                                    categoryId
                                      ? `/${storeId}/${harvestId}/ip/${categoryId}/${
                                          variant?.id
                                        }__${brand}__${normalizeNameForUrl(
                                          variant?.Name
                                        )}_${variant?.RegularPrice.toFixed(2)}`
                                      : `/${storeId}/${harvestId}/ips/${
                                          variant?.id
                                        }__${brand}__${normalizeNameForUrl(
                                          variant?.Name
                                        )}_${variant?.RegularPrice.toFixed(2)}`
                                  )
                                }
                              >
                                <CustomizedTooltip title={variant?.Name}>
                                  {variant?.Attribute[index]?.AttrValue !==
                                  "value" ? (
                                    <p>
                                      {" "}
                                      {variant?.Attribute[index]?.AttrValue}
                                    </p>
                                  ) : (
                                    <p>test</p>
                                  )}
                                </CustomizedTooltip>
                              </div>
                            );
                          } else if (
                            variant?.Attribute[index]?.AttrName &&
                            variant?.Attribute[index]?.AttrValue !== "value" &&
                            product?.id === variant?.id
                          ) {
                            const brand = variant?.Brand ? variant?.Brand : "";
                            return (
                              <div
                                className={
                                  product?.id === variant?.id ? "active" : ""
                                }
                                key={variant_index}
                                onClick={() =>
                                  navigate(
                                    categoryId
                                      ? `/${storeId}/${harvestId}/ip/${categoryId}/${
                                          variant?.id
                                        }__${brand}__${normalizeNameForUrl(
                                          variant?.Name
                                        )}_${variant?.RegularPrice.toFixed(2)}`
                                      : `/${storeId}/${harvestId}/ips/${
                                          variant?.id
                                        }__${brand}__${normalizeNameForUrl(
                                          variant?.Name
                                        )}_${variant?.RegularPrice.toFixed(2)}`
                                  )
                                }
                              >
                                <CustomizedTooltip title={variant?.Name}>
                                  {variant?.Attribute[index]?.AttrValue !==
                                  "value" ? (
                                    <p>
                                      {variant?.Attribute[index]?.AttrValue}
                                    </p>
                                  ) : (
                                    <p></p>
                                  )}
                                </CustomizedTooltip>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        }
                      )}
                    </div>
                  </div>
                )
              );
            })}
          </div>
        )}
    </>
  );
};

const CustomizedTooltip = MuiStyled(
  ({ className, title, icon, children }: any) => (
    <Tooltip title={title} classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));
