import React, { useRef } from "react";

import { Code } from "react-content-loader";

import { IProduct } from "../../types";
import { PdpGallery } from "../../components/PdpGallery";
// import { PdpCard } from "../../components/PdpCard/stare";

import { SimilarProducts } from "../../components/SimilarProducts";
import { usePdpRoute } from "./usePdpRoute";
import { PdpRouteStyled } from "./styles";
import { PdpRating } from "../../components/PdpRating";
import { PdpReviews } from "../../components/PdpReviews";
import { useParams } from "react-router-dom";
import { useConfiguration } from "../../hooks/useConfiguration";
import { IpdpField, PdpFieldName } from "../../types/config";
import { useQuery } from "@apollo/client";
import { GET_LOCALIZED } from "../../queries";
import { ExpantableComponent } from "../../components/ExpantableComponent";
import { useTheme } from "@mui/material/styles";
import { Slide } from "@mui/material";
import { PdpCard } from "../../components/PdpCard";
import ProductDescription from "../../components/ProductDescription";

export const PdpRoute = () => {
  const muiTheme = useTheme();
  const {
    loading,
    categoryLoading,
    categoryData,
    data,
    categoryId,
    shuffledProducts,
  } = usePdpRoute();

  const ratingSectionRef = useRef<HTMLDivElement>(null);
  const { storeId = "", harvestId = "" } = useParams();

  const { PDPFields, LanguageVersion } = useConfiguration({
    storeId,
    harvestId,
  });
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  if (loading || categoryLoading) {
    return (
      <div style={{ marginTop: 24 }}>
        <Code />
      </div>
    );
  } else {
    const categoryEdges = categoryData?.getEPogTermListing?.edges;
    const fullpath =
      categoryEdges && categoryEdges[0] && categoryEdges[0]?.node?.fullpath;
    const breadcrumbs = fullpath?.split(`/${harvestId}`)[1];

    const product = data.getEPog as IProduct;

    return (
      <PdpRouteStyled theme={muiTheme}>
        <div className="container">
          {categoryId && <div>{breadcrumbs}</div>}
          <div className="details">
            <PdpCard product={product} ratingSectionRef={ratingSectionRef} />
          </div>
        </div>
        {PDPFields &&
          Object.values(PDPFields).map((field: IpdpField, index: number) => {
            if (field.Name === PdpFieldName.Description) {
              return (
                <React.Fragment key={index}>
                  <div className="container">
                    <div className="description">
                      <ExpantableComponent
                        title={translation?.getLocalization.ProductDetails}
                        arrowPosition="right"
                      >
                        <ProductDescription content={product?.Description}/>
                      </ExpantableComponent>
                    </div>
                  </div>
                </React.Fragment>
              );
            }

            if (field.Name === PdpFieldName.ProductYouMakeLike) {
              return (
                <React.Fragment key={index}>
                  <div className="container">
                    <div className="blue-bg">
                      <h2>
                        {translation?.getLocalization.CustomersAlsoConsidered}
                      </h2>

                      <SimilarProducts
                        className="productYouMakeLike"
                        products={shuffledProducts?.shuffledSimilar}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            }

            if (field.Name === PdpFieldName.Recommended) {
              return (
                <React.Fragment key={index}>
                  <div className="container">
                    <div className="blue-bg">
                      <h3>{translation?.getLocalization.RecommendedForYou}</h3>
                      <SimilarProducts
                        className="recommended"
                        products={shuffledProducts?.shuffledRecommended}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            }

            if (field.Name === PdpFieldName.Reviews) {
              return (
                <React.Fragment key={index}>
                  <div className="container">
                    <div className="reviews" ref={ratingSectionRef}>
                      <PdpRating product={product} />
                    </div>
                  </div>
                </React.Fragment>
              );
            }
            return "";
          })}
      </PdpRouteStyled>
    );
  }
};
