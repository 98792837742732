import React from "react";

import { IProduct } from "../../types";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { ReviewsGraph } from "../ReviewsGraph";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { useConfiguration } from "../../hooks/useConfiguration";
import { GET_LOCALIZED } from "../../queries";
import { useQuery } from "@apollo/client";
import { theme } from "../../theme";
import { useTheme } from "@mui/material";

export const PdpRating = ({ product }: { product: IProduct }) => {
  const muiTheme = useTheme();
  const { storeId = "", termId, harvestId = "" } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  return (
    <PdpRatingStyled theme={muiTheme}>
      <div>
        <p>
          {product.RROverall} {translation?.getLocalization.OutOf}
        </p>
        <Stack spacing={1} className="stack">
          <StyledRating
            name="half-rating-read"
            defaultValue={product.RROverall}
            precision={0.5}
            readOnly
          />
          <Reviews>
            ({product.RRAmount} {translation?.getLocalization.Reviewers})
          </Reviews>
        </Stack>
      </div>
    </PdpRatingStyled>
  );
};

const PdpRatingStyled = styled.div<{ theme: any }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 33px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  background: ${({ theme }) => theme.palette.backgroundElement};
  color: ${({ theme }) => theme.palette.font};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

  & > div > p {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 25px;
  }

  .stack {
    margin-bottom: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .graph {
    display: none;
    min-width: 230px;
  }
`;
const StyledRating = styled(Rating)({
  "& .MuiRating-iconEmpty": {
    color: `${theme.kroger.grey}`,
  },
  "& .MuiRating-iconFilled": {
    color: `${theme.kroger.primary}`,
  },
});

const Reviews = styled.span`
font-size: 12px;
  margin: 0 0 0 8px !important;
`;
