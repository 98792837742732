import { PiScroll } from "react-icons/pi";
import { AiOutlineCreditCard } from "react-icons/ai";
import { IoFastFoodOutline } from "react-icons/io5";
import { BsCart3 } from "react-icons/bs";
import { useCartHook } from "../../routes/CartRoute/useCartHook";
import styled from "@emotion/styled";
import { useAppContext } from "../../context";
interface IBottomNavigationButton {
  iconName: string;
  label: string;
  active: boolean;
  icon: JSX.Element;
  onClick: () => void;
}

export const BottomNavigationButton = ({
  iconName,
  label,
  active,
  onClick,
  icon,
}: IBottomNavigationButton) => {
  const {
    value: { cart },
  } = useAppContext();
  const totalItemsInCart = cart.reduce(
    (total: any, item: any) => total + item.amount,
    0
  );
  const renderIcon = (iconName: string) => {
    const iconMap: { [key: string]: JSX.Element } = {
      home: icon,
      ads: <PiScroll fontSize={24} />,
      savings: <AiOutlineCreditCard fontSize={24} />,
      shop: <IoFastFoodOutline fontSize={24} />,
      cart: <BsCart3 fontSize={24} />,
    };

    return iconMap[iconName] || null;
  };

  const handleClick = () => {
    onClick();
  };

  return (
    <IconButton onClick={handleClick}>
      <IconWrapper active={active}>
        {renderIcon(iconName)}
        {label === "Cart" ? (
          <IconLabelBlue active={active}>{totalItemsInCart}</IconLabelBlue>
        ) : (
          <IconLabel active={active}>{label}</IconLabel>
        )}
      </IconWrapper>
    </IconButton>
  );
};

const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  width: 100px;

  -webkit-tap-highlight-color: transparent;
`;

const IconWrapper = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 20px;
  color: ${({ active, theme }: any) =>
    active
      ? theme.palette.bottomMenuBgFontActive
      : theme.palette.bottomMenuBgFontInactive};
`;

const IconLabel = styled.span<{ active: boolean }>`
  font-size: 10px;
  padding-top: 4px;
  font-weight: 600;
  margin-top: 1px;
  font-family: Poppins, sans-serif;
  color: ${({ active, theme }: any) =>
    active
      ? theme.palette.bottomMenuBgFontActive
      : theme.palette.bottomMenuBgFontInactive};
`;

const IconLabelBlue = styled(IconLabel)`
  background-color: ${({ theme }: any) => theme.palette.bottomMenuBgFontActive};
  color: ${({ theme }: any) => theme.palette.black};
  padding: 2px 6px;
  border-radius: 5px;
`;
