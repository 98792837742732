import React, { createContext, useReducer } from "react";
import { useImmerReducer } from "use-immer";
import { reducer } from "./reducer";
import { Icontext, Istate } from "./types";

const lightTheme = {
  background: "#FFFFFF",
  text: "#000000",
  // Add more theme variables as needed
};

const darkTheme = {
  background: "#1A202C",
  text: "#FFFFFF",
  // Add more theme variables as needed
};

const initialState: Istate = {
  isModal: false,
  isInlineFilters: false,
  modalType: undefined,
  searchFilters: [],
  productsSorting: [],
  cart: [],
  theme: "light", // Default theme
};

const AppContext = createContext<any>(initialState);

export const ContextProvider = ({ children }: Icontext) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);

  const toggleTheme = () => {
    dispatch({ type: "TOGGLE_THEME" });
  };

  const value = React.useMemo(
    () => ({
      ...state,
      toggleTheme,
    }),
    [state, toggleTheme]
  );

  return (
    <AppContext.Provider value={{ value, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = React.useContext(AppContext);

  if (!context) {
    throw new Error("useAppContext must be used under <ContextProvider/>");
  }
  return context;
};
