import React from "react";
import { HomeOffersItem } from "./HomeOffersItem";
import { BsQrCodeScan } from "react-icons/bs";
import { IoMdArrowForward } from "react-icons/io";
import styled from "@emotion/styled";

export const HomeOffersSlider = () => {
  const mainIcon = <BsQrCodeScan size={20} />;
  const navigationIcon = <IoMdArrowForward size={14} />;
  const header = "Kroger Pay";
  const subHeader = "Set up now";

  return (
    <StyledWrapper
      style={{
        display: "flex",
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        width: "100%",
        marginTop: "90px",
      }}
    >
      <HomeOffersItem
        icon={mainIcon}
        navigationIcon={navigationIcon}
        header={header}
        subHeader={subHeader}
      />
      <HomeOffersItem
        icon={mainIcon}
        navigationIcon={navigationIcon}
        header={header}
        subHeader={subHeader}
      />
      <HomeOffersItem
        icon={mainIcon}
        navigationIcon={navigationIcon}
        header={header}
        subHeader={subHeader}
      />
      <HomeOffersItem
        icon={mainIcon}
        navigationIcon={navigationIcon}
        header={header}
        subHeader={subHeader}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  width: 100%;
  margin-top: 90px;

  ::-webkit-scrollbar {
    display: none;
  }
`;
