import { TbSearch } from "react-icons/tb";
import { useNavigation } from "./useNavigation";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material";

export const Search = () => {
  const { handleSearchSuggestions, searchKeyword } = useNavigation();
  const mui = useTheme();

  return (
    <SearchInputWrapper theme={mui}>
      <SearchInputContainer>
        <SearchIcon>
          <TbSearch size="24px" color={"gray"} />
        </SearchIcon>
        <SearchInputStyled
          type="text"
          placeholder="Search or Scan Items"
          value={searchKeyword}
          onChange={(e) => handleSearchSuggestions(e.target.value)}
        />
      </SearchInputContainer>
    </SearchInputWrapper>
  );
};

const SearchInputWrapper = styled.div<{ theme: any }>`
  position: relative;
  width: 100%;
  margin: 0 16px;
`;

const SearchInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 0px;
`;

const SearchInputStyled = styled.input`
  width: 100%;
  padding: 10px;
  padding-left: 40px;
  border-radius: 12px;
  height: 50px;
  font-size: 15px;
  font-weight: 500;
  pointer-events: none;
  border: none;
  background-color: ${({ theme }: any) => theme.palette.greyLight};

  ::placeholder {
    font-weight: 500;
    padding-left: 10px;
    font-size: 16px;
    font-family: Poppins;
    color: ${({ theme }: any) => theme.palette.greyDark};
  }
`;

const SearchIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: ${({ theme }: any) => theme.palette.black};
`;
