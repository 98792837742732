import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { theme } from '../../theme';

export const PdpCardStyled = styled.div<{theme: any}>`
  margin-top: 80px;
  background: ${({theme}) => theme.palette.backgroundElement};
  color: ${({theme}) =>theme.palette.font};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 60px 6px 50px;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
  .sponsored-container{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    span{
      color: ${theme.kroger.grey};
      margin-right: 10px;
      margin-bottom: 10px;
      font-size: 12px;
    }
  }
  .product-name {
    display: flex;
    justify-content: center;
    width: 100%;

    p {
      width: 100%;
      max-width: 500px;
      font-weight: 700;
      font-size: 20px;
      margin: 10px;
      line-height: 24px;
      text-align: center;
    }
  }
  .addToCartPDPButton {
    margin: 15px 0 15px 0;
    float: right;
  }

  .attributes {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin: 10px 0;
  }

  .shipping-methods {
    .container {
      display: flex;
      gap: 10px;
    }
    p {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 24px;
      text-decoration-line: none;
      margin-bottom: 16px;
    }
    .description {
      margin: 20px 0px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }
  }

  & > p > span {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 24px;
  }
  .variants-container {
    & > p {
      margin: 10px 0;
    }
    & > .variants-inner-container {
      & > p {
        font-weight: 500;
        font-size: 12px;
        margin: 10px 0;
      }
  }
  .variants {
    display: flex;
    gap: 16px;
    padding-bottom: 10px;
    max-width: 312px;
    overflow-x: auto;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    .active {
      background-color: ${theme.kroger.productAddToCartBg};
      border: none;
      p{
        color: white;

      }
    }

    & > div {
      border: 1px solid ${({theme}) =>theme.palette.font};
      border-radius: 6px;
      min-width: 75px;
      max-height: 40px;
      padding: 8px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      white-space: nowrap;
      flex-shrink: 0;
      img {
        height: 85px;
        width: 85px;
        object-fit: contain;
        display: block;
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
      }
      .variant-price {
        font-weight: 700;
      }
    }
  }
}
`;

export const AddButton = styled.button`
  background: transparent;
  border: none;
  text-decoration: underline;
`;

export const ButtonStyled = styled(Button)`
  color: #ffffff;
  background: ${theme.kroger.primary};
  padding: 9px 16px;
  border-radius: 20px;
  font-family: Montserrat;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  &:hover {
    background: ${theme.kroger.primary};
  }
`;
export const StyledShippingMethodContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 2px solid ${theme.kroger.grey};
  border-radius: 10px;

  .price-container {
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-size: 28px;
    line-height: 22px;
    margin: 10px 0;

    & > span {
      font-weight: 400;
    }

    .regular-price {
      font-size: 30px;
      line-height: 1;
      font-weight: 600; 
    }

    .currency {
      font-size: 0.5em;
      line-height: 1;
      font-weight: 600;
      margin-right: 2px;
      vertical-align: top;
      position: relative;
      bottom: 12px;
    }

    .fraction {
      font-size: 0.5em;
      line-height: 1;
      font-weight: 600;
      vertical-align: top;
      position: relative;
      bottom: 14px; 
    }
  }

  .shipping-method {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.kroger.primary};
    font-size: 18px;
    cursor: pointer;
    span{
      margin-top: 6px;
      font-size: 26px;
    }
  }
`;
export const PromoLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
`
export const PromoLabel = styled.div<{ color?: string }>`
  display: inline-block;
  color: ${theme.kroger.white};
  background-color: ${({ color }) => color};
  font-size: 14px;
  padding: 8px; 
  border-radius: 5px;
  font-weight: 600;
  white-space: nowrap; 
`;

export const ShippingMethodStyled = styled.button<{
  isActive?: boolean;
}>`
  &:hover {
    cursor: pointer;
  }
  height: 40px;
  text-align: left;
  text-decoration: none;
  padding: 10px;
  flex: 1;
  background-color: ${({ isActive }) => (isActive ? '#f4fdfe' : '#ffffff')};
  box-shadow: ${({ isActive, theme }: any) =>
    isActive
      ? `0 0 0 0.125rem ${theme.productAddToCartBg}`
      : '0 0 0 0.0625rem #bbc8d1'};
  border-radius: 5px;
  border: none;

  white-space: nowrap;

  svg {
    color: ${({ theme }: any) => theme.productAddToCartBg};
  }
`;
