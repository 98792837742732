import React from "react";
import { IProduct } from "../../types";
import { BrandContainer, Container, Header, Paragraph } from "./styles";
import { imgSrcByEnv } from "../../../config/imgSrcByEnv";
import { TCurrency } from "../../types/config";
import { useParams } from "react-router-dom";
import { normalizeNameForUrl } from "../../helpers";
import { useTheme } from "@mui/material";
import { AdvBannerItem } from "../AdvBannerItem";
import { BannerVideo } from './styles';

export const AdvBanner = ({
  TermBannerImage,
  TermBannerProducts,
  TermBannerSponsoredBy,
  TermBannerText,
  TermBannerVideo,
  TermBannerVideoPosition,
}: {
  TermBannerImage: { fullpath: string };
  TermBannerProducts: IProduct[];
  TermBannerSponsoredBy: string;
  TermBannerText: string;
  AddProductButton?: string;
  Currency: TCurrency;
  TermBannerVideo?: string;
  TermBannerVideoPosition?: string;
}) => {
  const { storeId = "", categoryId, harvestId = "" } = useParams();
  const isVideoOnTop = TermBannerVideoPosition === 'top';
  const muiTheme = useTheme();

  return (<>
    {TermBannerVideo && isVideoOnTop &&
      <BannerVideo isVideoOnTop={isVideoOnTop} className={'promoBannerVideo'}
      src={`${imgSrcByEnv()}${TermBannerVideo}`} controls autoPlay muted loop playsInline />
    }
    <Container theme={muiTheme}>
      <BrandContainer>
        <div className="brand-container">
          <img
            src={`${imgSrcByEnv()}${TermBannerImage?.fullpath}`}
            alt={TermBannerSponsoredBy}
          />
          <div>
            {TermBannerText && <Header>{TermBannerText}</Header>}
            <Paragraph>Sponsored by {TermBannerSponsoredBy}</Paragraph>
          </div>
        </div>
      </BrandContainer>
      <div className="products-container">
        {TermBannerProducts?.map((product: IProduct) => {
          const { id, Name, URLPromoCode, Price, RegularPrice, Brand } =
            product;
          const price = Price !== 0 ? Price : RegularPrice;
          const brand = Brand ? Brand : "";
          let promoCode;

          if (URLPromoCode != null) {
            promoCode = "__promoCode-" + URLPromoCode;
          } else {
            promoCode = "__promoCode-";
          }
          const url = categoryId
            ? `/${storeId}/${harvestId}/ip/${categoryId}/${id}__${brand}__${normalizeNameForUrl(
                Name
              )}}_${price}${promoCode}`
            : `/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
                Name
              )}_${price}${promoCode}`;
          const onClickUrl = categoryId
            ? `/${storeId}/${harvestId}/ip/${categoryId}/${id}__${brand}__${normalizeNameForUrl(
                Name
              )}}_${price}${promoCode}`
            : `/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
                Name
              )}_${price}${promoCode}`;
          return (
            <AdvBannerItem
              key={id}
              product={product}
              onClick={onClickUrl}
              url={url}
            />
          );
        })}
      </div>
    </Container>
    {TermBannerVideo && !isVideoOnTop &&
          <BannerVideo isVideoOnTop={isVideoOnTop} className={'promoBannerVideo'} 
          src={`${imgSrcByEnv()}${TermBannerVideo}`} controls autoPlay muted loop playsInline />
    }
  </>);
};
