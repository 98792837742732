import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { theme } from "../../theme";
import { HomeProductsSliderHeader } from "../../routes/MainRoute/ProductsSlider/HomeProductsSliderHeader";

interface ICategory {
  key: string;
  id: string;
  fullpath: string;
}

export const PopularSections = ({
  categories,
}: {
  CarouselWidth?: string;
  categories?: { node: ICategory }[];
}) => {
  const navigate = useNavigate();
  const { storeId = "", harvestId = "" } = useParams();

  if (!categories || categories.length === 0) {
    return null;
  }

  return (
    <StyledWrapper>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {categories?.map(({ node: { key, id } }, index) => (
          <div
            onClick={() =>
              navigate(
                `/${storeId}/${harvestId}/category/${id}__${key.replaceAll(
                  " ",
                  "_"
                )}`
              )
            }
            key={index}
            style={{
              flex: "0 0 auto",
              padding: "0 6px",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "50px",
                  backgroundColor: "steelblue",
                  marginBottom: "8px",
                }}
              />
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textAlign: "center",
                }}
              >
                {key.split(" ").map((word, i) => (
                  <span key={i} style={{ display: "block" }}>
                    {word}
                  </span>
                ))}
              </p>
            </div>
          </div>
        ))}
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  overflow-x: auto;
  background: ${({ theme }: any) => theme.palette.background};
  color: ${({ theme }: any) => theme.palette.font};
  padding: 24px 0px;

  ::-webkit-scrollbar {
    display: none;
  }
`;
