import { light } from "@mui/material/styles/createPalette";
import { Itheme } from "./types";

export const theme: any = {
  amazon: {
    navBg: "#232F3E",
    menuBg: "#37475A",
    menuColor: "#FFFFFF",
    sliderBulletBg: "brown",
    buttonBannerBg: "#FF9900",
    productAddToCartBg: "#FF9900",
    footerFontColor: "#FFFFFF",
    footerMenuBg: "#232F3E",
    footerRightsBg: "#37475A",
  },

  target: {
    navBg: "#FFFFFF",
    navFontColor: "#000000",
    menuBg: "#BB271A",
    menuColor: "#FFFFFF",
    sliderBulletBg: "red",
    buttonBannerBg: "#BB271A",
    productAddToCartBg: "#BB271A",
    footerFontColor: "#666666",
    footerMenuBg: "#DBDBDB",
    footerRightsBg: "#666666",
    footerRightsColor: "#FFFFFF",
  },

  walmart: {
    navBg: "#2F71D5",
    menuBg: "#004F9A",
    menuColor: "#FFFFFF",
    sliderBulletBg: "blue",
    buttonBannerBg: "#2F71D5",
    productAddToCartBg: "#2F71D5",
    footerFontColor: "#ffffff",
    footerMenuBg: "#2F71D5",
    footerRightsBg: "2F71D5",
  },

  samsclub: {
    navBg: "#0067A0",
    menuBg: "#142A3C",
    menuColor: "#FFFFFF",
    sliderBulletBg: "#0067A0",
    buttonBannerBg: "#0067A0",
    productAddToCartBg: "#0067A0",
    footerFontColor: "#ffffff",
    footerMenuBg: "#142A3C",
    footerRightsBg: "#0067A0",

    primary: "#0072AB",
    white: "#ffffff",
    black: "#000000",
    grey: "#6b7279",
    greyLight: "#f3f4f4",
    greyDark: "#666",
    red: {
      dark: "#b20101",
    },
    yellow: "#fbdb14",
    orange: "#ffad1e",
    turquoise: "#07b8b3",
    greyVeryLight: "#fafafa",
    green: "#73b66f",
    steelblue: "#4682B4",
    blue: {
      medium: "steelblue",
      light: "#f0f8ff",
      steelblue: "#4682B4",
    },
  },

  realcanadiansuperstore: {
    navBg: "#FFFFFF",
    navFontColor: "#000000",
    menuBg: "#194B9F",
    menuColor: "#FFFFFF",
    sliderBulletBg: "#194B9F",
    buttonBannerBg: "#194B9F",
    productAddToCartBg: "#EE3124",
    footerFontColor: "#ffffff",
    footerMenuBg: "#191919",
    footerRightsBg: "##191919",
  },

  walgreens: {
    navBg: "#323264",
    navFontColor: "#FFFFFF",
    menuBg: "#F5F5F0",
    menuColor: "#323264",
    sliderBulletBg: "#323264",
    buttonBannerBg: "#323264 ",
    productAddToCartBg: "#323264",
    footerFontColor: "#323264",
    footerMenuBg: "#F5F5F0",
    footerRightsBg: "#FFFFFF",
  },

  walmartca: {
    navBg: "#FFFFFF",
    navFontColor: "#2F71D5",
    menuBg: "#2F71D5",
    menuColor: "#FFFFFF",
    sliderBulletBg: "#323264",
    buttonBannerBg: "#323264 ",
    productAddToCartBg: "#0072CD",
    footerFontColor: "#FFFFFF",
    footerMenuBg: "#0072CD",
    footerRightsBg: "#031D43",
  },

  tesco: {
    navBg: "#FFFFFF",
    navFontColor: "#00539F",
    menuBg: "#00539F",
    menuColor: "#FFFFFF",
    sliderBulletBg: "#00539F",
    buttonBannerBg: "#00539F",
    productAddToCartBg: "#00539F",
    footerFontColor: "#000000",
    footerMenuBg: "#F6F6F6",
    footerRightsBg: "#FFFFFF",
  },

  kroger: {
    navBg: "#FFFFFF",
    navFontColor: "#000000",
    menuBg: "#21409A",
    menuColor: "#FFFFFF",
    sliderBulletBg: "#3E74BB",
    productAddToCartBg: "#3E74BB",
    footerFontColor: "#000000",
    footerMenuBg: "#F6F6F6",
    footerRightsBg: "#FFFFFF",
    grey: "#6b7279",
    primary: "#0072AB",
    white: "#ffffff",
    black: "#000000",
    greyLight: "#e5e6e6",
    greyDark: "#666",
    red: {
      dark: "#b20101",
      light: "#d80404",
    },
    yellow: "#FFD241",
    orange: "#ffad1e",
    turquoise: "#07b8b3",
    greyVeryLight: "#fafafa",
    green: "#73b66f",
    steelblue: "#4682B4",
    blue: {
      medium: "steelblue",
      light: "#f0f8ff",
      steelblue: "#4682B4",
    },

    lightTheme: {
      primary: {
        main: "#21409A",
      },
      backgroundElement: "#fff",
      background: "#F3F2F7",
      bgFilter: "#F3F2F7",
      bgFilterElement: "#fff",
      font: "#000000",
      bgfilterHeader: "#0072AB",
      buttonBannerBg: "#3E74BB",
      bottomMenuBg: "#cadced",
      bottomMenuBgFontActive: "#789dcd",
      bottomMenuBgFontInactive: "#4a6583",

      navBg: "#FFFFFF",
      navFontColor: "#000000",
      menuBg: "#21409A",
      menuColor: "#FFFFFF",
      sliderBulletBg: "#3E74BB",
      productAddToCartBg: "#3E74BB",
      footerFontColor: "#000000",
      footerMenuBg: "#F6F6F6",
      footerRightsBg: "#FFFFFF",
      grey: "#6b7279",
      white: "#ffffff",
      black: "#000000",
      greyLight: "#e5e6e6",
      greyDark: "#666",
      red: {
        dark: "#b20101",
        light: "#d80404",
      },
      yellow: "#FFD241",
      orange: "#ffad1e",
      turquoise: "#07b8b3",
      greyVeryLight: "#fafafa",
      green: "#73b66f",
      steelblue: "#4682B4",
      blue: {
        medium: "steelblue",
        light: "#f0f8ff",
        steelblue: "#4682B4",
      },
    },
    darkTheme: {
      primary: {
        main: "#21409A",
      },
      background: "#000",
      backgroundElement: "#1E1E20",
      font: "#fff",
      bgFilter: "#1E1E20",
      bgFilterElement: "#000",
      bgfilterHeader: "#1E1E20",
      buttonBannerBg: "#3E74BB",
      bottomMenuBg: "#274262",
      bottomMenuBgFontActive: "#789dcd",
      bottomMenuBgFontInactive: "#4a6583",

      navBg: "#FFFFFF",
      navFontColor: "#000000",
      menuBg: "#21409A",
      menuColor: "#FFFFFF",
      sliderBulletBg: "#3E74BB",
      productAddToCartBg: "#3E74BB",
      footerFontColor: "#000000",
      footerMenuBg: "#F6F6F6",
      footerRightsBg: "#FFFFFF",
      grey: "#6b7279",
      white: "#ffffff",
      black: "#000000",
      greyLight: "#e5e6e6",
      greyDark: "#666",
      red: {
        dark: "#b20101",
        light: "#d80404",
      },
      yellow: "#FFD241",
      orange: "#ffad1e",
      turquoise: "#07b8b3",
      greyVeryLight: "#fafafa",
      green: "#73b66f",
      steelblue: "#4682B4",
      blue: {
        medium: "steelblue",
        light: "#f0f8ff",
        steelblue: "#4682B4",
      },
    },
  },

  loblaws: {
    navBg: "#000000",
    navFontColor: "#FFFFFF",
    menuBg: "#000000",
    menuColor: "#FFFFFF",
    sliderBulletBg: "#e1251b",
    buttonBannerBg: "#e1251b",
    productAddToCartBg: "#e1251b",
    footerFontColor: "#FFFFFF",
    footerMenuBg: "#000000",
    footerRightsBg: "#000000",
  },

  homedepot: {
    navBg: "#FFFFFF",
    navFontColor: "#212120",
    menuBg: "#F96302",
    menuColor: "#FFFFFF",
    sliderBulletBg: "#F96302",
    buttonBannerBg: "#F96302",
    productAddToCartBg: "#F96302",
    footerFontColor: "#212120",
    footerMenuBg: "#FFFFFF",
    footerRightsBg: "#F96302",
    footerRightsColor: "#FFFFFF",
  },

  asda: {
    navBg: "#FFFFFF",
    navFontColor: "#3d3d3d",
    menuBg: "#f6f6f6",
    menuColor: "#3d3d3d",
    sliderBulletBg: "#538316",
    buttonBannerBg: "#538316",
    productAddToCartBg: "#538316",
    footerFontColor: "#3d3d3d",
    footerMenuBg: "#f6f6f6",
    footerRightsBg: "#f6f6f6",
    footerRightsColor: "#3d3d3d",
  },
};
