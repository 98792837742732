import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import React from "react";
import { IoMdArrowForward } from "react-icons/io";

export const HomeProductsSliderHeader = ({
  header,
  subHeader,
}: {
  header: string;
  subHeader: string;
}) => {
  const muiTheme = useTheme();

  return (
    <Container theme={muiTheme}>
      <Header>
        <Title>{header}</Title>
        <ShopAllContainer>
          <ShopAllText>{subHeader}</ShopAllText>
          <IoMdArrowForward size={16} />
        </ShopAllContainer>
      </Header>
    </Container>
  );
};

const Container = styled.div<{ theme: any }>`
  margin-top: 8px;
  color: ${({ theme }: any) => theme.palette.font};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 24px 0;
`;

const Title = styled.p`
  font-size: 24px;
  font-weight: 900;
`;

const ShopAllContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ShopAllText = styled.p`
  font-size: 12px;
  font-weight: 800;
  margin-right: 10px;
`;
