import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useConfiguration } from '../../hooks/useConfiguration';
import { useParams } from 'react-router-dom';
import { actions } from '../../context/actions';
import { useAppContext } from '../../context';
import { IProduct } from '../../types';
import { contentSquarePageEvent, dynataAddToCart } from '../../helpers';
import { TextAndStepper } from './variants/TextAndStepper';
export const AddToCartButton = ({ product, url }: any) => {
  const [newAmount, setNewAmount] = useState(0);
  const { storeId = '', harvestId = '' } = useParams();
  const { AddProductButton } = useConfiguration({
    storeId,
    harvestId,
  });
  const { AddToCartButtonName } = product;
  const {
    dispatch,
    value: { cart },
  } = useAppContext();

  const handleAddToCartSubmit = (e: any) => {
    e && e.preventDefault();
    contentSquarePageEvent('addToCart');
    if (newAmount > 0) {
      dispatch({
        type: actions.ADD_TO_CART,
        payload: { ...product, newAmount },
      });
      dynataAddToCart(url + '_cartadd' + newAmount);
    }
    if (newAmount === 0) {
      dynataAddToCart(url + '_cartadd' + newAmount);
      dispatch({
        type: actions.REMOVE_FROM_CART,
        payload: { type: 'REMOVE_ALL', product },
      });
    }
  };
  useEffect(() => {
    const productInCart = cart?.find(
      (productInCart: IProduct) => product.id === productInCart?.id
    );

    setNewAmount(productInCart?.amount || 0);
  }, [cart, product]);

  const handleAddToCartChange = (type: string) => {
    setTimeout(() => {
      if (type === 'plus') {
        contentSquarePageEvent('addToCartIncrease');
        dynataAddToCart(url + '_cartadd' + (newAmount + 1));
        dispatch({ type: actions.ADD_TO_CART, payload: { ...product } });
      }
      if (type === 'minus') {
        contentSquarePageEvent('addToCartDecrease');
        if (newAmount <= 0) {
          dynataAddToCart(url + '_cartadd0');
        } else {
          dynataAddToCart(url + '_cartadd' + (newAmount - 1));
        }
        dispatch({
          type: actions.REMOVE_FROM_CART,
          payload: { product, type: 'REMOVE_ONE' },
        });
      }
    }, 100);
  };

  return (
    <Wrapper>
      <TextAndStepper
        newAmount={newAmount}
        AddProductButton={
          AddToCartButtonName ? AddToCartButtonName : AddProductButton
        }
        handleAddToCartSubmit={handleAddToCartSubmit}
        handleAddToCartChange={handleAddToCartChange}
        variant={'small'}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 15px;
`;
