import React from 'react';

import { useParams } from 'react-router-dom';
import { useAppContext } from '../../context';
import { actions } from '../../context/actions';
import { useConfiguration } from '../../hooks/useConfiguration';
import { IProduct } from '../../types';
import { ModalTypes } from '../Modals/types';

export const useFilters = ({
  products,
  productsBeforeFiltering,
}: {
  products: IProduct[];
  productsBeforeFiltering: IProduct[];
}) => {
  const [isPriceFilter, setIsPriceFilter] = React.useState(false);
  const [isBrandsFilter, setIsBrandsFilter] = React.useState(false);
  const [isCategoriesFilter, setIsCategoriesFilter] = React.useState(false);
  const [isSpecialOffersFilter, setIsSpecialOffersFilter] = React.useState(false);
  const [isCustomFilter, setIsCustomFilter] = React.useState<string[]>([]);

  const { storeId = '', harvestId = '' } = useParams();
  const { FilterVisibility } = useConfiguration({ storeId, harvestId });

  const {
    value: { searchFilters, isInlineFilters, isModal },
    dispatch,
  } = useAppContext();

  const priceFilter = searchFilters.find(
    (filter: any) => filter.name === 'Price'
  );

  const handleFiltersVisibility = () => {
    if (window.innerWidth < 768) {
      dispatch({
        type: actions.TOGGLE_MODAL,
        payload: {
          isModal: !isModal,
          modalType: ModalTypes.filtersMenu,
          modalContent: { productsBeforeFiltering },
        },
      });
    } else {
      if (FilterVisibility === 'Stick') {
        dispatch({
          type: actions.TOGGLE_INLINE_FILTERS,
          payload: {
            isInlineFilters: !isInlineFilters,
          },
        });
      } else {
        dispatch({
          type: actions.TOGGLE_MODAL,
          payload: {
            isModal: !isModal,
            modalType: ModalTypes.filtersMenu,
            modalContent: { productsBeforeFiltering },
          },
        });
      }
    }
  };

  React.useEffect(() => {
    if (
      priceFilter?.userSelectedPriceFilterMin ||
      priceFilter?.userSelectedPriceFilterMax
    ) {
      setIsPriceFilter(true);
    } else {
      setIsPriceFilter(false);
    }
  }, [searchFilters, priceFilter]);

  React.useEffect(() => {
    const brandsFilter = searchFilters.find(
      (filter: any) => filter.name === 'Brand'
    );
    const brands =
      brandsFilter && brandsFilter.brands && Object.values(brandsFilter.brands);
    const isBrands = brands && brands.some((brand: any) => brand === true);
    setIsBrandsFilter(isBrands);
  }, [searchFilters]);

  React.useEffect(() => {
    const categoriesFilter = searchFilters.find(
      (filter: any) => filter.name === 'Categories'
    );
    const categories =
    categoriesFilter && categoriesFilter.categories && Object.values(categoriesFilter.categories);
    const isCategory = categories && categories.some((categories: any) => categories === true);
    setIsCategoriesFilter(isCategory);
  }, [searchFilters]);

  React.useEffect(() => {
    const specialOffersFilter = searchFilters.find(
      (filter: any) => filter.name === 'Special Offers'
    );
    const promo =
    specialOffersFilter && specialOffersFilter.promo && Object.values(specialOffersFilter.promo);
    const isPromo = promo && promo.some((promo: any) => promo === true);
    setIsSpecialOffersFilter(isPromo);
  }, [searchFilters]);

  React.useEffect(() => {
    if (searchFilters?.length < 1) setIsCustomFilter([]);
    const customFilter = searchFilters.findLast(
      (filter: any) => filter.name.includes("Custom_") // === 'Custom_Pampers'
    );
    const CustomFilter =
    customFilter && customFilter.name; //&& Object.values(customFilter.value);
    if (CustomFilter != undefined && !isCustomFilter.includes(CustomFilter)) {
      setIsCustomFilter(isCustomFilter => [...isCustomFilter!, CustomFilter]);
    } else if (isCustomFilter.includes(CustomFilter)) {
      let removeCustomFilter = isCustomFilter.filter((Filter: string) => Filter != CustomFilter)
      setIsCustomFilter(removeCustomFilter);
    }
  }, [searchFilters]);

  //on filters component first load initialize price range minimum and maximum within searchFilters store
  return {
    isPriceFilter,
    isBrandsFilter,
    isCategoriesFilter,
    isSpecialOffersFilter,
    isCustomFilter,
    handleFiltersVisibility,
  };
};
