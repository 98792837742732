import React from 'react';
import { IProduct } from '../../types';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import {
  DescriptionContainer,
  LinkStyled,
  ProductContainer,
  ProductHeader,
} from './styles';
import { useParams } from 'react-router-dom';
import { Rating, Stack, useTheme } from '@mui/material';
import { AddToCartButton } from '../AddToCartButton';
import { useConfiguration } from '../../hooks/useConfiguration';

interface IAdvBannerItem {
  product: IProduct;
  onClick: string;
  url: string;
}
export const AdvBannerItem = ({ product, onClick, url }: IAdvBannerItem) => {
  const muiTheme = useTheme();

  const { Name, RROverall } = product;

  const price = product.Price !== 0 ? product.Price : product.RegularPrice;
  const { storeId = '', harvestId = '' } = useParams();
  const { Currency } = useConfiguration({
    storeId,
    harvestId,
  });
  return (
    <LinkStyled to={onClick}>
      <ProductContainer theme={muiTheme}>
        <div>
          <img
            src={`${imgSrcByEnv()}${product?.Gallery[0]?.image?.fullpath}`}
            alt="product"
          />
          <AddToCartButton product={product} url={url} />
        </div>
        <div>
          <ProductHeader>{Name}</ProductHeader>
          <DescriptionContainer>
            {RROverall && (
              <Stack spacing={1}>
                <Rating value={RROverall} precision={0.5} readOnly />
              </Stack>
            )}
            <p>
              {Currency ? Currency : '$'}
              {price}
            </p>
          </DescriptionContainer>
        </div>
      </ProductContainer>
    </LinkStyled>
  );
};
