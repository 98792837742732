import { IoChevronBackOutline } from "react-icons/io5";
import { Menu } from "./Menu";
import { useQuery } from "@apollo/client";
import { GET_PRODUCT_CATEGORY_BY_TERM } from "../../queries";
import { useParams, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";

export const Header = ({
  handleGoToPreviousScreen,
  storeId,
  harvestId,
}: {
  handleGoToPreviousScreen: () => void;
  storeId: string;
  harvestId: string;
}) => {
  const { termId } = useParams();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const muiTheme = useTheme();
  const { data: nameData } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({ termId }),
    {
      skip: !termId,
    }
  );

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  const cartPath = currentPath.endsWith("/cart");
  const termListingName = nameData?.getEPogTermListing?.edges[0]?.node?.Name;

  const capitalizeFirstLetterOfEachWord = (str: string) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const capitalizedTermListingName = termListingName
    ? capitalizeFirstLetterOfEachWord(termListingName)
    : "";

  return (
    <HeaderContainer>
      {cartPath ? (
        <CloseButton onClick={handleGoToPreviousScreen} theme={muiTheme}>
          Close
        </CloseButton>
      ) : (
        <StyledPreviousScreenButtonWrapper left>
          <StyledPreviousScreenButton onClick={handleGoToPreviousScreen} />
        </StyledPreviousScreenButtonWrapper>
      )}
      <ScreenTitle>
        {cartPath ? "Cart" : capitalizedTermListingName || "Product Details"}
      </ScreenTitle>
      <IconGroup>
        {!cartPath && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Menu iconSize={24} />
          </div>
        )}
      </IconGroup>
    </HeaderContainer>
  );
};

const StyledPreviousScreenButtonWrapper = styled.div<{ left?: boolean }>`
  justify-content: center;
  user-select: none;
  width: 80px;
  padding-left: ${({ left }) => (left ? "12px" : "0px")};
  margin-right: ${({ left }) => (left ? "0px" : "8px")};
`;

const StyledPreviousScreenButton = styled(IoChevronBackOutline)`
  font-size: 24px;
  color: ${({ theme }: any) => theme.palette.font};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 26px 0px;
  position: relative;
  height: 78px;
`;

const CloseButton = styled.p<{ theme: any }>`
  font-size: 16px;
  margin-left: 12px;
  width: 60px;
  font-weight: 600;
  cursor: pointer;
  position: absolute;
  left: 12px;
  color: ${({ theme }) => theme.palette.font};
`;

const ScreenTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: ${({ theme }: any) => theme.palette.font};
`;

const IconGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 14px;
  width: 80px;
`;
