import React from "react";

import styled from "@emotion/styled";

import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useConfiguration } from "../../hooks/useConfiguration";
import { IProduct } from "../../types";
import Pagination from "@mui/material/Pagination";
import { ProductCard } from "../ProductCard";
import { useAppContext } from "../../context";
import { GET_LOCALIZED } from "../../queries";
import {
  normalizeNameForUrl,
  contentSquereCustomTag,
  artificialPageview,
} from "../../helpers";
import { imgSrcByEnv } from "../../../config/imgSrcByEnv";
import { TCurrency } from '../../types/config';
import { AdvBanner } from '../AdvBanner';

export const ProductsContainer = ({
  products,
  name,
  promoBannerData,
}: {
  products: IProduct[];
  name: string;
  promoBannerData?: {
    TermBannerImage: { fullpath: string };
    TermBannerProducts: IProduct[];
    TermBannerSponsoredBy: string;
    TermBannerText: string;
    AddProductButton?: string;
    Currency: TCurrency;
  };
}) => {
  const {
    value: { isInlineFilters },
  } = useAppContext();

  const {
    storeId = "",
    categoryId,
    termId,
    pageNr,
    harvestId = "",
  } = useParams();

  const navigate = useNavigate();

  const { Columns, Rows, LanguageVersion } = useConfiguration({
    storeId,
    harvestId,
  });
  const currentPage = Number(pageNr) || 1;
  const [productLength, setProductLength] = React.useState(0);
  let itemsPerPage = Columns === 3 ? 9 : 16;

  if (Rows && Columns) {
    itemsPerPage = Columns * Rows;
  }

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  const handlePaginationChange = (event: any, value: any) => {
    navigate(
      categoryId
        ? `/${storeId}/${harvestId}/category/${categoryId}/${value}`
        : `/${storeId}/${harvestId}/search/${termId}/${value}`
    );
  };

  React.useEffect(() => {
    setProductLength(products.filter((item) => item).length);
  }, [products]);

  React.useEffect(() => {
    if (window.location.pathname.includes("category")) {
      contentSquereCustomTag(
        1,
        "Path",
        window.location.pathname + window.location.hash.replace("#", "?__"),
        ""
      );
      contentSquereCustomTag(2, "pageType", "Category", "");
      artificialPageview(
        window.location.pathname + window.location.hash.replace("#", "?__")
      );
    }
    if (window.location.pathname.includes("search")) {
      contentSquereCustomTag(
        1,
        "Path",
        window.location.pathname + window.location.hash.replace("#", "?__"),
        ""
      );
      contentSquereCustomTag(2, "pageType", "SERP", "");
      artificialPageview(
        window.location.pathname + window.location.hash.replace("#", "?__")
      );
    }
  }, [name]);

  return (
    <ProductsContainerStyled
      Columns={Columns}
      isInlineFilters={isInlineFilters}
      count={productLength}
    >
      <div className="products">
        {products.length > 0 &&
          products
            .slice(
              currentPage === 1 ? 0 : currentPage * itemsPerPage - itemsPerPage,
              currentPage * itemsPerPage
            )
            .map((product: IProduct, index: number) => {
              const isPromoBanner = product?.Video?.title === '__promoBannerPlaceholder__';
              let itemIndex = index;
              const { id, Name, URLPromoCode, Price, RegularPrice, Brand } =
                product;
              const price = Price ? Price : RegularPrice;
              const brand = Brand ? Brand : "";
              if (isPromoBanner && !products[index - 1]?.fullpath?.includes("Products")) // prev video
                itemIndex = index - 1;
              if (isPromoBanner && !products[index + 1]?.fullpath?.includes("Products")) // next video
                itemIndex = index + 1;
              const position = itemsPerPage * (currentPage - 1) + itemIndex + 1;

              let promoCode;

              if (URLPromoCode != null) {
                promoCode = "__promoCode-" + URLPromoCode;
              } else {
                promoCode = "__promoCode-";
              }

              let newColumns = 4;
              let cssClass = '';
              if (Columns !== undefined) { newColumns = Columns; }
              if (position === 1 || position % newColumns === 1) { cssClass = 'fullsize'; }
              if (isPromoBanner) {
                return promoBannerData?.TermBannerProducts && <AdvBanner {...promoBannerData} />
              } else if (!product?.fullpath?.includes("Products")) {
                return (
                  <div className={'video '+cssClass}>
                    <video src={`${imgSrcByEnv()}${product?.Video?.data.fullpath}`} controls autoPlay muted loop />
                  </div>
                )
              } else {
              return (
                product && (
                  <ProductCard
                    key={id}
                    product={product}
                    url={
                      categoryId
                        ? `/${storeId}/${harvestId}/ip/${categoryId}/${id}__${brand}__${normalizeNameForUrl(
                            Name
                          )}}_${price}_${position}${promoCode}`
                        : `/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
                            Name
                          )}_${price}_${position}${promoCode}`
                    }
                    onClick={
                      categoryId
                        ? `/${storeId}/${harvestId}/ip/${categoryId}/${id}__${brand}__${normalizeNameForUrl(
                            Name
                          )}}_${price}_${position}${promoCode}`
                        : `/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
                            Name
                          )}_${price}_${position}${promoCode}`
                    }
                  />
                )
              );}
            })}
      </div>
      {productLength ? (
        <div className="pagination">
          <Pagination
            shape="rounded"
            count={Math.ceil(productLength / itemsPerPage)}
            page={currentPage}
            onChange={handlePaginationChange}
            hidePrevButton={currentPage === 1}
            hideNextButton={
              currentPage === Math.ceil(productLength / itemsPerPage)
            }
          />
        </div>
      ) : (
        ""
      )}
    </ProductsContainerStyled>
  );
};

const ProductsContainerStyled = styled.div<{
  Columns?: number;
  isInlineFilters: boolean;
  count?: number;
}>`
  .products {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-left: 5px;
  }
  .fullsize {
    width: 100%;

    grid-column: 1 / span 4;

     @media (max-width: 1140px) {
      grid-column: auto;
    }
    @media (max-width: 768px) {
      grid-column: 1 / span 2;
    }
    @media (max-width: 550px) {
      grid-column: 1 / span 1;
    }
  }
    
  .video{
    margin-left:auto;
    margin-right:auto;
    video {
      width: 100%;
      padding: 10px 0 0 0;
    }
  }

`;
