import React from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { FilterModalCommonStyles } from "../commonStyles";
import styled from "@emotion/styled";
import { actions } from "../../../context/actions";
import { useAppContext } from "../../../context";
import { useParams } from "react-router-dom";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { GET_LOCALIZED } from "../../../queries";
import { useQuery } from "@apollo/client";
import { theme } from "../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { IconButton, useTheme } from "@mui/material";

export const VariantsFilter = ({ buttonsOff, header, handleClose }: any) => {
  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();
  const muiTheme = useTheme();
  const variantsFilter = searchFilters.find(
    (filter: any) => filter.name === "Variants"
  );
  const { storeId = "", termId, harvestId = "" } = useParams();
  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  const [value, setValue] = React.useState(variantsFilter?.value || "");

  const handleVariantChange = (newValue: string) => {
    if (newValue === value) {
      setValue("");
      dispatch({
        type: actions.ADD_SEARCH_FILTER,
        payload: { name: "Variants", value: "" },
      });
    } else {
      setValue(newValue);
      dispatch({
        type: actions.ADD_SEARCH_FILTER,
        payload: { name: "Variants", value: newValue },
      });
    }
  };

  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff} theme={muiTheme}>
      {header && (
        <HeaderStyled>
          <span>Variants</span>
          <IconButtonStyled onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButtonStyled>
        </HeaderStyled>
      )}
      <ComponentStyled>
        <FormControl>
          <RadioGroup value={value}>
            <StyledClickableLabel onClick={() => handleVariantChange("yes")}>
              <StyledFormControlLabel
                value="yes"
                control={<StyledRadio theme={muiTheme} />}
                label={translation?.getLocalization.ShowProductWithVariants}
                labelPlacement="end"
              />
            </StyledClickableLabel>
            <StyledClickableLabel onClick={() => handleVariantChange("no")}>
              <StyledFormControlLabel
                value="no"
                control={<StyledRadio theme={muiTheme} />}
                label={translation?.getLocalization.HideProductWithVariants}
                labelPlacement="end"
              />
            </StyledClickableLabel>
          </RadioGroup>
        </FormControl>
      </ComponentStyled>
    </FilterModalCommonStyles>
  );
};

const ComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  .MuiFormControlLabel-label {
    font-size: 18px;
  }
`;

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid ${theme.kroger.grey};
`;

const StyledClickableLabel = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  pointer-events: none;
  width: 100%;
  .MuiFormControlLabel-labelPlacementStart {
    order: -1;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: ${theme.kroger.green};
    font-size: 24px;
  }
  .MuiFormControlLabel-label {
    display: flex;
    align-items: center;
  }
`;

const StyledRadio = styled(Radio)<{ theme: any }>`
  pointer-events: auto;
  &.Mui-checked {
    color: ${theme.kroger.primary};
  }
  .MuiSvgIcon-root {
    font-size: 24px;
  }
  &.MuiRadio-root:not(.Mui-checked) {
    color: ${({ theme }) => theme.palette.font};
  }
`;

export const IconButtonStyled = styled(IconButton)`
  & > svg {
    color: ${theme.kroger.greyDark};
  }
`;

export const ButtonsContainer = styled.div`
  background-color: #fff;
  padding: 16px;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 70px;
  z-index: 1000;
`;
