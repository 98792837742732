/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface HomeOffersItemProps {
  icon: JSX.Element;
  navigationIcon: JSX.Element;
  header: string;
  subHeader: string;
}

export const HomeOffersItem = ({
  icon,
  navigationIcon,
  header,
  subHeader,
}: HomeOffersItemProps) => {
  return (
    <Container>
      <ContentWrapper>
        <IconWrapper>{icon}</IconWrapper>
        <TextWrapper>
          <Header>{header}</Header>
          <SubHeaderWrapper>
            <SubHeader>{subHeader}</SubHeader>
            <NavigationIcon>{navigationIcon}</NavigationIcon>
          </SubHeaderWrapper>
        </TextWrapper>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 8px;
  margin-right: 6px;
  background-color: ${({ theme }: any) => theme.palette.buttonBannerBg};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 140px;
  justify-content: space-around;
  align-items: center;
  color: ${({ theme }: any) => theme.palette.white};
`;

const IconWrapper = styled.div`
  display: flex;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
`;

const SubHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SubHeader = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin: 0;
`;

const NavigationIcon = styled.p`
  font-weight: 400;
  margin: 0;
`;
