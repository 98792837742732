import styled from '@emotion/styled';
import Button from '@mui/material/Button';

export const Container = styled.div<{ theme: any }>`
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  border-radius: 10px;
  padding: 40px 5px 40px 5px;
  background: ${({ theme }) => theme.palette.background};
  color: ${({ theme }) => theme.palette.font};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  @media (max-width: 650px) {
    padding: 15px;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    text-align: center;
  }

  .products-container {
    display: flex;
    flex-direction: row;
    gap: 15px;
    overflow-x: auto;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
  }
`;

export const BrandContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  gap: 15px;
  width: auto;
  & > div {
   width: 100px;
  }
  @media (max-width: 768px) {
    display: block;
  }
  img {
    height: 120px;
    max-width: 100px;
    object-fit: contain;
  }
`;

export const Header = styled.h1`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;
`;


export const Paragraph = styled.p`
  height: 17px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 34px;
`;

export const ButtonCustom = styled(Button)`
  max-width: 120px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  background: transparent;
  border-radius: 3px;
  border: 1px solid ${(props: any) => props.theme.productAddToCartBg};
  padding: 3px 11px;
`;


export const BannerVideo = styled.video<{isVideoOnTop:boolean}>`
display:block;
width:100%;
margin: 0 auto;
margin-bottom: ${({ isVideoOnTop }) => isVideoOnTop ? "0" : "10px"};
`