import React from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import { ModalTypes } from "./types";
import { useAppContext } from "../../context";
import { FiltersMenu } from "../FiltersMenu";
import { actions } from "../../context/actions";
import { useConfiguration } from "../../hooks/useConfiguration";
import { BrandsFilterModal } from "../Filters/BrandsFilter";
import { CategoriesFilterModal } from "../Filters/CategoriesFilter";
import { VariantsFilter } from "../Filters/VariantsFilter";
import { SpecialOffersFilterModal } from "../Filters/SpecialOffersFilter";
import { PriceRangeFilter } from "../Filters/PriceRangeFilter";
import { Sorting } from "../Filters/Sorting";
import { RatingFilter } from "../Filters/RatingFilter";
import { ShippingMethod } from "../PdpCard/ShippingMethod";
// import { ShippingMethod } from "../PdpCard/stare/ShippingMethod";

export const ModalComponent = () => {
  const {
    value: { isModal, modalType, modalContent },
    dispatch,
  } = useAppContext();

  const { storeId = "", harvestId = "" } = useParams();

  const { FilterPosition } = useConfiguration({ storeId, harvestId });

  const handleClose = () =>
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: { isModal: false, modalContent: undefined },
    });

  const modalSelector = () => {
    switch (modalType as ModalTypes) {
      case ModalTypes.filtersMenu:
        return (
          <FiltersMenu
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
            handleClose={handleClose}
          />
        );

      case ModalTypes.priceRange:
        return (
          <PriceRangeFilter
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
          />
        );

      case ModalTypes.brands:
        return (
          <BrandsFilterModal
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
          />
        );

      case ModalTypes.sorting:
        return <Sorting />;

      case ModalTypes.rating:
        return <RatingFilter />;

      case ModalTypes.variants:
        return <VariantsFilter />;

      case ModalTypes.specialOffers:
        return (
          <SpecialOffersFilterModal
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
          />
        );

      case ModalTypes.categories:
        return (
          <CategoriesFilterModal
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
          />
        );
      case ModalTypes.shippingMethod:
        return (
          <ShippingMethod
            product={modalContent.product}
            shipmentMethods={modalContent.shipmentMethods}
            shippingMethodSelected={modalContent.shippingMethodSelected}
            setShippingMethodSelected={modalContent.setShippingMethodSelected}
          />
        );
      default:
        return false;
    }
  };

  return (
    <DialogStyled
      open={isModal}
      onClose={handleClose}
      modalType={modalType}
      FilterPosition={FilterPosition}
    >
      {modalSelector()}
    </DialogStyled>
  );
};

const DialogStyled = styled(Dialog, {
  shouldForwardProp(propName) {
    return propName !== "modalType" && propName !== "FilterPosition";
  },
})<{
  modalType: ModalTypes;
  FilterPosition?: string;
}>`
  .MuiDialog-root {
    position: fixed;
    width: 100%;
    height: ${({ modalType }) =>
      modalType === ModalTypes.shippingMethod ? "500px" : "auto"};
    margin: 0;
    z-index: ${({ modalType }) =>
      modalType === ModalTypes.shippingMethod ? "1500" : "1300"};
  }

  .MuiDialog-container {
    display: flex;
    justify-content: ${({ modalType, FilterPosition }) => {
      if (modalType === ModalTypes.filtersMenu) {
        if (FilterPosition === "Left") {
          return "flex-start";
        } else {
          return "flex-end";
        }
      }
      return "center";
    }};
    align-items: flex-end;
  }

  .MuiDialog-paper {
    width: 100%;
    margin: 0;
    height: ${({ modalType }) =>
      modalType === ModalTypes.filtersMenu ? "100vh" : "auto"};
    border-radius: ${({ modalType }) =>
      modalType === ModalTypes.filtersMenu ? "0" : "20px 20px 0px 0px"};
    max-height: 100%;
  }

  .MuiBackdrop-root {
    background-color: ${({ modalType }) =>
      modalType === ModalTypes.filtersMenu
        ? "transparent"
        : "rgba(0, 0, 0, 0.5)"};
  }
`;
