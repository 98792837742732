import styled from "@emotion/styled";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Fade, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../theme";

const Container = styled.div<{ theme: any }>`
  background: ${({ theme }) => theme.palette.backgroundElement};
  color: ${({ theme }) => theme.palette.font};
  margin-top: 10px;
  padding: 25px 5px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 10px;
`;

const Header = styled.div<{ arrowPosition: any }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: ${({ arrowPosition }: any) =>
    arrowPosition === "right" ? "space-between" : "initial"};
`;

const Content = styled(Box)`
  max-width: 400px;
  margin: 10px 20px;

  img {
    max-width: 400px;
    width: 100%;
    height: auto;
  }

  table {
    max-width: 400px;
    width: 100%;
    overflow-x: auto;
    display: block;
  }
`;
const Title = styled(Typography)`
  font-weight: 600;
`;
const Arrow = styled.span<{ arrowPosition: any }>`
  color: ${theme.kroger.primary};
  ${({ arrowPosition }: any) =>
    arrowPosition === "right"
      ? "position: absolute; right: 10px;"
      : "margin: 0 10px;"}
`;

export const ExpantableComponent = ({
  title,
  children,
  fontSize = "18px",
  arrowPosition = "left",
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const muiTheme = useTheme();
  const toggleContent = () => setIsOpen(!isOpen);

  return (
    <Container theme={muiTheme}>
      <Header onClick={toggleContent} arrowPosition={arrowPosition}>
        {arrowPosition === "left" && (
          <Arrow arrowPosition={arrowPosition}>
            <FontAwesomeIcon
              className="faIcon"
              icon={isOpen ? faChevronUp : faChevronDown}
            />
          </Arrow>
        )}
        <Title style={{ fontSize }}>{title}</Title>
        {arrowPosition === "right" && (
          <Arrow arrowPosition={arrowPosition}>
            <FontAwesomeIcon
              className="faIcon"
              icon={isOpen ? faChevronUp : faChevronDown}
            />
          </Arrow>
        )}
      </Header>
      <Fade in={isOpen} unmountOnExit>
        <Content>{children}</Content>
      </Fade>
    </Container>
  );
};
