import { useTheme } from "@mui/material";
import { HomeProductsSlider } from "./HomeProductsSlider";
import { useMainRoute } from "./useMainRoute";
import { theme } from "../../theme/index";
import styled from "@emotion/styled";
import React from "react";
import { useQuery } from "@apollo/client";
import { GET_SIMILAR_PRODUCTS } from "../../queries";
import { useParams } from "react-router-dom";

export const HomeProductsSection = ({
  title,
  labels,
  header,
  subHeader,
}: {
  title?: string;
  labels: string[];
  header: string;
  subHeader: string;
}) => {
  const { storeId, harvestId } = useParams();

  const [shuffledProducts, setShuffledproducts] = React.useState({
    shuffledSimilar: [],
    shuffledRecommended: [],
  });
  const { data: similiarData, loading: similarLoading } = useQuery(
    GET_SIMILAR_PRODUCTS,
    {
      variables: {
        fullpath: `/ePog Product Data/Products/${storeId}/${harvestId}`,
      },
    }
  );
  const theme = useTheme();
  React.useEffect(() => {
    if (!similarLoading) {
      const products = !similarLoading && similiarData?.getHarvest?.children;

      const handleShuffling = (items: any) =>
        items
          ?.map((value: any) => ({ value, sort: Math.random() }))
          ?.sort((a: any, b: any) => a.sort - b.sort)
          ?.map(({ value }: any) => value)
          ?.filter((product: any) => product?.Name);

      const shuffledSimilar = handleShuffling(products)?.slice(0, 10);
      const shuffledRecommended = handleShuffling(products)?.slice(0, 10);

      setShuffledproducts({ shuffledSimilar, shuffledRecommended });
    }
  }, [similarLoading, similiarData]);

  const recommended = shuffledProducts.shuffledRecommended;
  const similar = shuffledProducts.shuffledSimilar;

  return (
    <StyledHomeProductsSection theme={theme}>
      <HomeProductsSlider
        shuffledProducts={title?.includes("Trending") ? recommended : similar}
        title={title}
        labels={labels}
        header={header}
        subHeader={subHeader}
      />
    </StyledHomeProductsSection>
  );
};

const StyledHomeProductsSection = styled.div`
  box-sizing: border-box;
  padding: 10px 0px;
`;
