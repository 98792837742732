import styled from '@emotion/styled';

export const ProductDescriptionStyled = styled.div`
.swiper {
  min-height:100px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    & > div:nth-child(2) {
      position: absolute;
      left: 5%;
      max-width: 50%;
      padding: 10px;
      background: #000000bd;
      color:white;
      * {
        margin:unset;
        color:white;
      }
    }
  }
  
  .swiper-pagination .swiper-pagination-bullet:not(.swiper-pagination-bullet-active){
    background:white;
  }

  @media screen and (max-width:768px){
    .swiper-slide {
      text-align: center;
      display: flex;
      flex-direction:column;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      & > div:nth-child(n){
        position: static;
        max-width: unset;
        padding: 10px;
        background: none;
        color:black;
      }
    }
    .swiper-pagination{
      position:static;
    }
  }
  
}

`