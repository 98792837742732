import React from "react";
import { IProduct } from "../../types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../context";
import { actions } from "../../context/actions";
import { useQuery } from "@apollo/client";
import { GET_LOCALIZED, GET_PRODUCT_CATEGORY_BY_TERM } from "../../queries";
import { PdpGallery } from "../PdpGallery";
import { useConfiguration } from "../../hooks/useConfiguration";
import { RatingSection } from "./RatingSection";
import {
  PdpCardStyled,
  PromoLabel,
  PromoLabelContainer,
  StyledShippingMethodContainer,
} from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox, faCar, faTruck } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@mui/material";
import { VariantSelector } from "./VariantSelector";
import { CartControls } from "./CartControls";
import { IoIosArrowDown } from "react-icons/io";
import { ModalTypes } from "../Modals/types";
import { PriceDisplay } from "./PriceDisplay";
import { contentSquarePageEvent } from "../../helpers";

export const PdpCard = ({
  product,
  ratingSectionRef,
}: {
  product: IProduct;
  ratingSectionRef: any;
}) => {
  const muiTheme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [prevKey, setPrevKey] = React.useState("");
  const [newAmount, setNewAmount] = React.useState(0);
  const { storeId = "", harvestId = "" } = useParams();
  const {
    dispatch,
    value: { cart },
  } = useAppContext();
  const { Currency, ShipmentMethod } = useConfiguration({
    storeId,
    harvestId,
  });

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  let href = window.location.pathname;

  const currency = Currency ? Currency : "$";

  const [shippingMethodSelected, setShippingMethodSelected] = React.useState(
    {}
  );

  const pricePerUnit =
    product.PricePerUnit && product.PricePerUnit != "0"
      ? " (" + product.PricePerUnit + ")"
      : "";

  const shipmentMethods = React.useMemo(() => {
    return (
      ShipmentMethod?.map((item) => {
        const getIcon = (type: string) => {
          type = type.toLowerCase();

          if (type === "shipping") {
            return <FontAwesomeIcon icon={faTruck} />;
          }
          if (type === "pickup") {
            return <FontAwesomeIcon icon={faCar} />;
          }
          if (type === "delivery") {
            return <FontAwesomeIcon icon={faBox} />;
          }
        };

        const getDesc = (type: string) => {
          type = type.toLowerCase();

          if (type === "shipping") {
            return "Get it in 2-3 days, Free for Plus, From $5.50 for Club Members";
          }
          if (type === "pickup") {
            return "Get it in 2 hours, Free for Plus, $4 for Club Members";
          }
          if (type === "delivery") {
            return "Get it in 3 hours, $8 for Plus, $12 for Club Members";
          }
        };

        return {
          icon: getIcon(item.Name),
          name: item.Name,
          description: getDesc(item.Name),
        };
      }) || []
    );
  }, [ShipmentMethod]);

  React.useEffect(() => {
    setShippingMethodSelected(shipmentMethods?.[0]?.name.toLowerCase());
  }, [shipmentMethods]);

  const productInCart = cart.find(
    (productInCart: IProduct) => product.id === productInCart?.id
  );

  React.useEffect(() => {
    setNewAmount(productInCart?.amount || 1);
  }, [cart, product]);
  React.useEffect(() => {
    if (
      location.state?.prevPath.split("_cartadd")[0] ===
      location.pathname.split("_cartadd")[0]
    ) {
      setPrevKey(location.state.prevKey);
    }
  }, [location]);
  React.useEffect(() => {
    setPrevKey(location.key);
  }, []);
  const handleAddToCart = () => {
    const navState = { prevPath: location.pathname, prevKey: prevKey };
    if (newAmount > 0 && href.indexOf("_cartadd") === -1) {
      dispatch(
        {
          type: actions.ADD_TO_CART,
          payload: {
            ...product,
            newAmount,
            shippingMethod: shippingMethodSelected,
          },
        },
        navigate(href + "_cartadd" + newAmount, {
          replace: true,
          state: navState,
        })
      );
    } else {
      dispatch(
        {
          type: actions.ADD_TO_CART,
          payload: {
            ...product,
            newAmount,
            shippingMethod: shippingMethodSelected,
          },
        },
        contentSquarePageEvent("addToCart"),
        navigate(href.replace(/_cartadd\d?/, "") + "_cartadd" + newAmount, {
          replace: true,
          state: navState,
        })
      );
    }

    if (newAmount === 0) {
      dispatch({
        type: actions.REMOVE_FROM_CART,
        payload: { type: "REMOVE_ALL", product },
      });
    }
  };
  const handleIncrement = () => {
    const newAmountIncremented = newAmount + 1;
    setNewAmount(newAmountIncremented);
    dispatch({
      type: actions.ADD_TO_CART,
      payload: {
        ...product,
        newAmount: newAmountIncremented,
        shippingMethod: shippingMethodSelected,
      },
    });
    navigate(href.replace(/_cartadd\d+/, `_cartadd${newAmountIncremented}`), {
      replace: true,
      state: { prevPath: location.pathname, prevKey: prevKey },
    });
  };

  const handleDecrement = () => {
    if (newAmount > 1) {
      const newAmountDecremented = newAmount - 1;
      setNewAmount(newAmountDecremented);
      dispatch({
        type: actions.ADD_TO_CART,
        payload: {
          ...product,
          newAmount: newAmountDecremented,
          shippingMethod: shippingMethodSelected,
        },
      });
      navigate(href.replace(/_cartadd\d+/, `_cartadd${newAmountDecremented}`), {
        replace: true,
        state: { prevPath: location.pathname, prevKey: prevKey },
      });
    } else {
      setNewAmount(0);
      dispatch({
        type: actions.REMOVE_FROM_CART,
        payload: { type: "REMOVE_ALL", product },
      });
      navigate(href.replace(/_cartadd\d+/, "_cartadd0"), {
        replace: true,
        state: { prevPath: location.pathname, prevKey: prevKey },
      });
    }
  };

  const handleChangeShipping = () => {
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: {
        isModal: true,
        modalType: ModalTypes.shippingMethod,
        modalContent: {
          product,
          shipmentMethods,
          shippingMethodSelected,
          setShippingMethodSelected,
        },
      },
    });
  };
  const handleScrollToRating = () => {
    if (ratingSectionRef.current) {
      ratingSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <PdpCardStyled theme={muiTheme}>
      {product.Sponsored && (
        <div className="sponsored-container">
          <span>Sponsored</span>
        </div>
      )}
      <PdpGallery product={product} />
      <PriceDisplay
        currency={currency}
        price={product.Price}
        regularPrice={product.RegularPrice}
        pricePerUnit={pricePerUnit}
      />
      {product.LabelText && (
        <PromoLabelContainer>
          <PromoLabel color={product.LabelColorCode}>
            {product.LabelText}
          </PromoLabel>
        </PromoLabelContainer>
      )}
      <div className="product-name">
        <p>{product.Name}</p>
      </div>

      <RatingSection
        product={product}
        onRatingAmountClick={handleScrollToRating}
      />
      <VariantSelector product={product} />
      {shipmentMethods.length ? (
        <StyledShippingMethodContainer onClick={handleChangeShipping}>
          <PriceDisplay
            currency={currency}
            price={product.Price}
            regularPrice={product.RegularPrice}
            pricePerUnit={pricePerUnit}
          />
          <div className="shipping-method">
            Ship
            <span>
              <IoIosArrowDown />
            </span>
          </div>
        </StyledShippingMethodContainer>
      ) : (
        ""
      )}
      <CartControls
        productInCart={productInCart}
        newAmount={newAmount}
        handleIncrement={handleIncrement}
        handleDecrement={handleDecrement}
        handleAddToCart={handleAddToCart}
        translation={translation}
      />
    </PdpCardStyled>
  );
};
