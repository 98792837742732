import React from "react";
import {
  ProductCartStyled,
  DescriptionContainer,
  LinkStyled,
  ImageStyled,
  SponsoredStyled,
  NameStyled,
  PriceStyled,
  PriceContainerStyled,
  ButtonContainerStyled,
  ImageWrapperContainer,
  PricePerUnitStyled,
  OldPriceStyled,
  OldPriceContainerStyled,
  NewPriceStyled,
  PromoLabelStyled,
} from "./styles";
import { IProduct } from "../../types";
import { useConfiguration } from "../../hooks/useConfiguration";
import { useParams } from "react-router-dom";
import { IAddProductButtonFunctionality } from "../../types/config";
import { AddToCartButton } from "../AddToCartButton";
import { imgSrcByEnv } from "../../../config/imgSrcByEnv";
import { useTheme } from "@mui/material";

export const ProductCard = ({
  product,
  onClick,
  url,
}: {
  product: IProduct;
  onClick: string;
  url: string;
}) => {
  const muiTheme = useTheme();
  const { storeId = "", harvestId = "" } = useParams();
  const { Currency } = useConfiguration({
    storeId,
    harvestId,
  });

  const currency = Currency ? Currency : "$";
  const pricePerUnit =
    product.PricePerUnit && product.PricePerUnit != "0"
      ? product.PricePerUnit
      : null;

  const price =
    product?.Price !== 0
      ? product?.Price?.toFixed(2)
      : product?.RegularPrice?.toFixed(2);
  const [priceIntegerPart, priceFractionalPart] = price ? price.split("."):[0,0];

  return (
    <LinkStyled to={onClick}>
      <ProductCartStyled muiTheme={muiTheme}>
        <ImageWrapperContainer>
          <ImageStyled
            src={`${imgSrcByEnv()}${
              product?.Gallery && product?.Gallery[0]?.image?.fullpath
            }`}
            alt="gallery fullpath"
          />
          {product.LabelText && (
            <PromoLabelStyled>{product.LabelText}</PromoLabelStyled>
          )}
        </ImageWrapperContainer>
        <DescriptionContainer>
          {product.Sponsored && <SponsoredStyled>Sponsored</SponsoredStyled>}
          <PriceContainerStyled>
            {product.Price && product.Price !== 0 ? (
              <OldPriceContainerStyled>
                <NewPriceStyled>
                  <sup>{currency}</sup>
                  <p>{priceIntegerPart}</p>
                  <sup>{priceFractionalPart}</sup>
                </NewPriceStyled>
                <OldPriceStyled>
                  ${product.RegularPrice.toFixed(2)}
                </OldPriceStyled>
              </OldPriceContainerStyled>
            ) : (
              <PriceStyled>
                <sup>{currency}</sup>
                {priceIntegerPart}
                <sup>{priceFractionalPart}</sup>
              </PriceStyled>
            )}

            {pricePerUnit && (
              <PricePerUnitStyled>
                {currency}
                {pricePerUnit}
              </PricePerUnitStyled>
            )}
          </PriceContainerStyled>
          <NameStyled>{product.Name}</NameStyled>
          <ButtonContainerStyled>
            <div className="promo">
              {product.PromoImage && (
                <img
                  src={`${imgSrcByEnv()}${
                    product?.PromoImage && product?.PromoImage.fullpath
                  }`}
                  alt="promo information"
                />
              )}
            </div>
            <AddToCartButton
              type={IAddProductButtonFunctionality.text}
              product={product}
              url={url}
            />
          </ButtonContainerStyled>
        </DescriptionContainer>
      </ProductCartStyled>
    </LinkStyled>
  );
};
