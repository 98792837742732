/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "../../theme";

const easeInQuart = "cubic-bezier(0.895, 0.03, 0.685, 0.22)";
const easeOutQuart = "cubic-bezier(0.165, 0.84, 0.44, 1)";
const boxSize = 20;
const checkThickness = 2;

const CheckboxContainer = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const HiddenCheckbox = styled.input`
  display: none;
`;

const StyledSpan = styled.span`
  position: relative;
  display: flex;
  width: ${boxSize}px;
  height: ${boxSize}px;
  border: 1px solid ${theme.target.grey};
  border-radius: 5px;
  background-color: transparent;
  box-sizing: border-box;
  overflow: hidden;
  margin-right: 10px;

  i {
    position: relative;
    width: 15px;
    height: 20px;
    margin-left: ${boxSize / 10}px;
    transform: rotate(-50deg) translateX(-5%) translateY(-5%) scale(1);
    transform-origin: center left;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: white;
      border-radius: ${boxSize}px;
    }

    &:before {
      height: 0%;
      top: 50%;
      left: 0;
      width: ${checkThickness}px;
      transition: height 300ms ${easeInQuart};
    }

    &:after {
      width: 0%;
      bottom: 0;
      left: 0;
      height: ${checkThickness}px;
      transition: width 300ms 300ms ${easeOutQuart};
    }
  }
`;

const checkedStyles = css`
  border: none;
  background-color: ${theme.samsclub.primary};

  i:before {
    height: 50%;
  }

  i:after {
    width: 100%;
  }
`;

interface CustomCheckboxProps {
  name: string;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  name,
  checked,
  onChange,
}) => {
  return (
    <CheckboxContainer>
      <HiddenCheckbox
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <StyledSpan css={checked ? checkedStyles : undefined}>
        <i></i>
      </StyledSpan>
    </CheckboxContainer>
  );
};
