import { useConfiguration } from "../../hooks/useConfiguration";
import { Code } from "react-content-loader";
import { useParams } from "react-router-dom";
import { HomeCategoriesSlider } from "./HomeCategoriesSlider";
import { HomeProductsSection } from "./HomeProductsSliderSection";
import { ImageCarousel } from "../../components/Carousel/ImageCarousel";
import image_2 from "../../../assets/images/image_2.webp";
import image_3 from "../../../assets/images/image_3.webp";
import image_6 from "../../../assets/images/image_6.webp";
import banner_1 from "../../../assets/images/banner_1.jpeg";
import banner_2 from "../../../assets/images/banner_2.jpg";
import footer_1 from "../../../assets/images/footer_1.png";

import styled from "@emotion/styled";
import { HomeOffersSlider } from "./HomeOffersSlider";
import { HomeProductsSliderHeader } from "./ProductsSlider/HomeProductsSliderHeader";

export const MainRoute = () => {
  const { storeId = "", harvestId = "" } = useParams();
  const { loading } = useConfiguration({ storeId, harvestId });

  const imagesArray = [image_2, image_3, image_6, image_2];
  const firstLabelsArray = ["Buy Again", "Your Sale Items", "Recent Items"];
  const secondlabelsArray = ["Digital Coupons", "Weekly Ad"];

  if (loading) {
    return (
      <div style={{ marginTop: 24 }}>
        <Code />
      </div>
    );
  } else {
    return (
      <StyledComponentWrapper>
        <HomeOffersSlider />
        <ImageCarousel CarouselItems={imagesArray} />
        <HomeProductsSection
          labels={firstLabelsArray}
          header={"Start Your Cart"}
          subHeader={"Shop All Items"}
        />
        <img
          src={banner_1}
          alt=""
          style={{ width: "100%", borderRadius: "12px", objectFit: "contain" }}
        />
        <HomeProductsSection
          labels={secondlabelsArray}
          header={"Savings"}
          subHeader={"View All Savings"}
        />
        <img
          src={banner_2}
          alt=""
          style={{ width: "100%", borderRadius: "12px", objectFit: "contain" }}
        />
        <HomeProductsSliderHeader
          header="Shop Categories"
          subHeader="Shop All"
        />
        <HomeCategoriesSlider />
        <img
          src={footer_1}
          alt=""
          style={{ width: "100%", borderRadius: "12px", objectFit: "contain" }}
        />
        <div style={{ marginBottom: "70px" }}></div>
      </StyledComponentWrapper>
    );
  }
};

const StyledComponentWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  margin-top: 78px;
  padding: 0px 12px;
`;
