import React from "react";
import styled from "@emotion/styled";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import { useAppContext } from "../../../context";
import { actions } from "../../../context/actions";
import { FilterModalCommonStyles } from "../commonStyles";
import { useParams } from "react-router-dom";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { GET_LOCALIZED } from "../../../queries";
import { useQuery } from "@apollo/client";
import { theme } from "../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { IconButton, useTheme } from "@mui/material";

export const Sorting = ({ buttonsOff, header, handleClose }: any) => {
  const [value, setValue] = React.useState("");

  const { storeId = "", harvestId = "" } = useParams();
  const muiTheme = useTheme();
  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  const {
    dispatch,
    value: { productsSorting },
  } = useAppContext();

  const handleChange = (newValue: string) => {
    if (newValue === value) {
      setValue("");
      dispatch({
        type: actions.ADD_PRODUCTS_SORTING,
        payload: { value: "" },
      });
    } else {
      setValue(newValue);
      dispatch({
        type: actions.ADD_PRODUCTS_SORTING,
        payload: { value: newValue },
      });
    }
  };

  React.useEffect(() => {
    const initialSortingValue =
      productsSorting && productsSorting[0]?.value
        ? productsSorting[0].value
        : "";
    setValue(initialSortingValue);
  }, [productsSorting]);

  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff} theme={muiTheme}>
      {header && (
        <HeaderStyled>
          <span>Sort by</span>
          <IconButtonStyled onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButtonStyled>
        </HeaderStyled>
      )}
      <ComponentStyled>
        <SortComponentStyled>
          <div className="sort-list-container">
            <div className="sort-list">
              <FormControl>
                <RadioGroup value={value}>
                  <StyledClickableLabel
                    onClick={() => handleChange("Alphabet:A>Z")}
                  >
                    <StyledFormControlLabel
                      value="Alphabet:A>Z"
                      control={<StyledRadio theme={muiTheme} />}
                      label={translation?.getLocalization.AlphabetAZ}
                      labelPlacement="end"
                    />
                  </StyledClickableLabel>
                  <StyledClickableLabel
                    onClick={() => handleChange("Alphabet:Z>A")}
                  >
                    <StyledFormControlLabel
                      value="Alphabet:Z>A"
                      control={<StyledRadio theme={muiTheme} />}
                      label={translation?.getLocalization.AlphabetZA}
                      labelPlacement="end"
                    />
                  </StyledClickableLabel>
                  <StyledClickableLabel
                    onClick={() => handleChange("Price:low>high")}
                  >
                    <StyledFormControlLabel
                      value="Price:low>high"
                      control={<StyledRadio theme={muiTheme} />}
                      label={translation?.getLocalization.PriceLH}
                      labelPlacement="end"
                    />
                  </StyledClickableLabel>
                  <StyledClickableLabel
                    onClick={() => handleChange("Price:high>low")}
                  >
                    <StyledFormControlLabel
                      value="Price:high>low"
                      control={<StyledRadio theme={muiTheme} />}
                      label={translation?.getLocalization.PriceHL}
                      labelPlacement="end"
                    />
                  </StyledClickableLabel>
                  <StyledClickableLabel
                    onClick={() => handleChange("Rating:low>high")}
                  >
                    <StyledFormControlLabel
                      value="Rating:low>high"
                      control={<StyledRadio theme={muiTheme} />}
                      label={translation?.getLocalization.RatingLH}
                      labelPlacement="end"
                    />
                  </StyledClickableLabel>
                  <StyledClickableLabel
                    onClick={() => handleChange("Rating:high>low")}
                  >
                    <StyledFormControlLabel
                      value="Rating:high>low"
                      control={<StyledRadio theme={muiTheme} />}
                      label={translation?.getLocalization.RatingHL}
                      labelPlacement="end"
                    />
                  </StyledClickableLabel>
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </SortComponentStyled>
      </ComponentStyled>
    </FilterModalCommonStyles>
  );
};

const ComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-size: 1.5rem;
  border-bottom: 1px solid ${theme.kroger.grey};
`;

export const IconButtonStyled = styled(IconButton)`
  & > svg {
    color: ${theme.kroger.grey};
  }
`;

const SortComponentStyled = styled.div`
  flex: 1;
  overflow: auto;

  .sort-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
  }
  .sort-list-container {
    flex: 1;
    overflow: auto;
  }
  .sort-list {
    width: 100%;
    .MuiFormControl-root {
      width: 100%;
    }
    .MuiFormControlLabel-label {
      font-size: 18px;
    }
  }
  svg {
    font-size: 24px;
  }
`;

const StyledClickableLabel = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  pointer-events: none;
  .MuiFormControlLabel-labelPlacementStart {
    order: -1;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: ${theme.kroger.green};
    font-size: 24px;
  }
  .MuiFormControlLabel-label {
    flex-grow: 1;
    text-align: left;
  }
`;

const StyledRadio = styled(Radio)<{ theme: any }>`
  pointer-events: auto;
  &.Mui-checked {
    color: ${theme.kroger.primary};
  }
  &.MuiRadio-root:not(.Mui-checked) {
    color: ${({ theme }) => theme.palette.font};
  }
`;

export const ButtonsContainer = styled.div`
  background-color: ${theme.kroger.white};
  padding: 16px;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 70px;
  z-index: 1000;
`;
