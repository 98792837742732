import React from 'react';
import { Stepper } from './Stepper';
import styled from '@emotion/styled';
import { theme } from '../../../theme';

export const TextAndStepper = ({
  handleAddToCartChange,
  newAmount,
  AddProductButton,
}: any) => {
  const preventDefault = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (newAmount === 0) handleAddToCartChange('plus');
    e.preventDefault();
  };

  return (
    <TextAndStepperStyle
      onClick={preventDefault}
      backgroundColor={
        newAmount > 0 ? 'transparent' : theme.kroger.productAddToCartBg
      }
    >
      {newAmount > 0 ? (
        <Stepper
          handleAddToCartChange={handleAddToCartChange}
          newAmount={newAmount}
        />
      ) : (
        <p>{AddProductButton}</p>
      )}
    </TextAndStepperStyle>
  );
};

export const TextAndStepperStyle = styled.div<{
  backgroundColor: any;
}>`
  position: relative;
  background: ${({ backgroundColor }) => backgroundColor};
  color: white;
  border-radius: 20px;
  border: none;
  font-family: Montserrat;
  letter-spacing: 0em;
  height: 35px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;

  & > p {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 13px;
    font-weight: 600;
  }
`;
