import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import { VariantsFilter } from "../Filters/VariantsFilter";
import { BrandsFilterModal } from "../Filters/BrandsFilter";
import { FiltersMenuStyled } from "./styles";
import { useFiltersMenu } from "./useFiltersMenu";
import { IProduct } from "../../types";
import { Sorting } from "../Filters/Sorting";
import { PriceRangeFilter } from "../Filters/PriceRangeFilter";
import { RatingFilter } from "../Filters/RatingFilter";
import { useQuery } from "@apollo/client";
import { GET_LOCALIZED, GET_PRODUCT_CATEGORY_BY_TERM } from "../../queries";
import { useNavigate, useParams } from "react-router-dom";
import { useConfiguration } from "../../hooks/useConfiguration";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Slide, useTheme } from "@mui/material";
import { useAppContext } from "../../context";

export const FiltersMenu = ({
  productsBeforeFiltering,
  handleClose,
}: {
  productsBeforeFiltering: IProduct[];
  handleClose?: any;
}) => {
  const {
    modalType,
    handleFiltersVisibility,
    isOpen,
    handleOpen,
    handleReset,
    isInlineFilters,
  } = useFiltersMenu();

  const { storeId = "", harvestId = "", termId } = useParams();
  const muiTheme = useTheme();
  const { LanguageVersion } = useConfiguration({ storeId, harvestId });
  const { value } = useAppContext();
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });
  const { data: nameData, loading: nameLoading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({ termId }),
    {
      skip: !termId,
    }
  );
  const name = nameData?.getEPogTermListing?.edges[0]?.node?.Name;

  const filtersList = value.searchFilters.length > 0;

  const filters = [
    {
      name: translation?.getLocalization.SortBy,
      component: <Sorting buttonsOff={true} />,
    },
    {
      name: translation?.getLocalization.Price,
      component: (
        <PriceRangeFilter
          buttonsOff={true}
          productsBeforeFiltering={productsBeforeFiltering}
        />
      ),
    },
    {
      name: translation?.getLocalization.Rating,
      component: <RatingFilter buttonsOff={true} />,
    },
    {
      name: translation?.getLocalization.Variants,
      component: <VariantsFilter buttonsOff={true} />,
    },
    {
      name: translation?.getLocalization.Brand,
      component: (
        <BrandsFilterModal
          productsBeforeFiltering={productsBeforeFiltering}
          buttonsOff={true}
        />
      ),
    },
  ];

  const container = {
    hidden: { height: 0, opacity: 0 },
    visible: {
      height: "auto",
      opacity: 1,
    },
  };
  return (
    <Slide direction="up" in={true} unmountOnExit timeout={200}>
      <FiltersMenuStyled
        modalType={modalType}
        isInlineFilters={isInlineFilters}
        theme={muiTheme}
      >
        <div className="filter-header">
          <span onClick={handleFiltersVisibility}>Cancel</span>
          <span>Sort & Filters</span>
        </div>
        <div className="filter-bar">
          <span>Filtering search result for {name}</span>
        </div>

        <div className="filters">
          {filters.map((filter: any, index: number) => (
            <React.Fragment key={index}>
              <div className="filter" onClick={() => handleOpen(filter?.name)}>
                <span>{filter.name}</span>
                {isOpen[filter.name] ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>

              {isOpen[filter.name] && (
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={container}
                >
                  {filter.component}
                </motion.div>
              )}
            </React.Fragment>
          ))}
        </div>

        <div className="filter-footer">
          {filtersList && (
            <button className="reset-button" onClick={handleReset}>
              {translation?.getLocalization.Clear}
            </button>
          )}
          <button className="result-button" onClick={handleClose}>
            {translation?.getLocalization.ViewResults}
          </button>
        </div>
      </FiltersMenuStyled>
    </Slide>
  );
};
