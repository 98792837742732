import { theme } from '../../theme';
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #000000;
`;
export const ProductCartStyled = styled.div<{ muiTheme: any }>`
  display: flex;
  max-width: 100vw;
  padding: 15px 0;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background-color: ${({ muiTheme }) => muiTheme.palette.backgroundElement};
  color: ${({ muiTheme }) => muiTheme.palette.font};
`;

export const ImageWrapperContainer = styled.div`
position: relative;
width: 40%;
height: 150px;
overflow: hidden;
padding: 10px;
;
`
export const ImageStyled = styled.img`
width: 100%;
height: 100%;
border-radius: 10px;
object-fit: contain;
box-sizing: border-box;
background-color: ${theme.kroger.white};


`;

export const PromoLabelStyled = styled.span`
position:absolute;
top:0px;
right: 20px;
padding: 5px;
border-radius: 5px;
font-weight: 600;
color: ${theme.kroger.white};
background-color: ${theme.kroger.red?.light};
`


export const DescriptionContainer = styled.div`
  width: 60%;
  position: relative;

  padding-right:30px;
  padding-bottom:20px;

  font-weight: 600;
  font-family: 'Montserrat';
  .promo {
    margin-top: 10px;
    & > img {
      width: 100%;
    }
  }

`;
export const SponsoredStyled = styled.p`
  color: ${theme.kroger.menuColor};
`;

export const PriceContainerStyled = styled.div`
 font-weight: 700;

`
export const OldPriceContainerStyled = styled.div`
display: inline-flex;
    font-size: 32px;
`
export const NewPriceStyled = styled.div`
display: flex;
background-color: ${theme.kroger.yellow};
 color: ${theme.kroger.black};
border-radius: 5px;
padding: 1px 4px;
      & > sup {
      font-size: 20px;
      margin-right: 2px;
    }
`

export const OldPriceStyled = styled.span`
margin-left: 5px;
 text-decoration:line-through; 
 font-size: 14px;
 font-weight: 800;
`

export const PriceStyled = styled.p`
    font-size: 32px;
    & > sup {
      font-size: 16px;
      margin-right: 2px;
    }
`


export const PricePerUnitStyled = styled.p`
margin-top: 5px;
  color: ${theme.kroger.menuColor};
`
export const NameStyled = styled.p`
font-size: 16px;
  margin-top: 5px;
  line-height: 20px;
      display: -webkit-box;
    overflow: hidden;
      text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
`;



export const ButtonContainerStyled = styled.div``