import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { theme } from '../../../theme';

export const Stepper = ({ newAmount, handleAddToCartChange }: any) => {
  const muiTheme = useTheme();

  return (
    <StepperContainerStyled>
      <IconButtonStyled
        muitheme={muiTheme}
        onClick={() => {
          handleAddToCartChange('minus');
        }}
      >
        <FontAwesomeIcon className="expand" icon={faMinus} />
      </IconButtonStyled>
      <AmountContainerStyled muitheme={muiTheme}>
        <p>{newAmount}</p>
        <p>in Cart</p>
      </AmountContainerStyled>
      <IconButtonStyled
        muitheme={muiTheme}
        onClick={() => {
          handleAddToCartChange('plus');
        }}
      >
        <FontAwesomeIcon className="expand" icon={faPlus} />
      </IconButtonStyled>
    </StepperContainerStyled>
  );
};

const StepperContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconButtonStyled = styled(IconButton)<{ muitheme: any }>`
  color: white;

  &:first-of-type {
    border: 1px solid ${theme.kroger.greyDark};
    color: ${theme.kroger.greyDark};
  }

  &:last-of-type {
    background-color: transparent;
    border: 1px solid ${theme.kroger.productAddToCartBg};
    color: ${theme.kroger.productAddToCartBg};
  }
`;

const AmountContainerStyled = styled.div<{ muitheme: any }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > :first-of-type {
    font-size: 14px;
    color: ${({ muitheme }) => muitheme.palette.font};
  }
  & > :last-of-type {
    font-size: 12px;
    font-weight: 400;
    color: grey;
  }
`;
