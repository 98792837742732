import React from "react";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";
import { theme } from "../../theme";
import { ButtonStyled } from "./styles";

export const CartControls = ({
  productInCart,
  newAmount,
  handleIncrement,
  handleDecrement,
  handleAddToCart,
  translation,
}: any) => {
  return (
    <CartControlsContainer>
      {productInCart?.amount > 0 && newAmount > 0 ? (
        <div className="cart-manipulation">
          <IconButton
            size="small"
            className="decrement"
            onClick={handleDecrement}
          >
            <FontAwesomeIcon className="expand" icon={faMinus} />
          </IconButton>
          <div className="quantity">
            <span>{newAmount}</span>
            <span>In cart</span>
          </div>
          <IconButton
            size="small"
            className="increment"
            onClick={handleIncrement}
          >
            <FontAwesomeIcon className="expand" icon={faPlus} />
          </IconButton>
        </div>
      ) : (
        <ButtonStyled onClick={handleAddToCart}>
          {translation?.getLocalization.AddToCart}
        </ButtonStyled>
      )}
    </CartControlsContainer>
  );
};

const CartControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  .cart-manipulation {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .quantity {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        margin: 0 10px;
        font-size: 16px;
      }
      span:nth-of-type(2) {
        color: gray;
      }
    }
    .decrement {
      border: 1px solid ${theme.kroger.productAddToCartBg};
      color: ${theme.kroger.productAddToCartBg};
      width: 40px;
      height: 40px;
      svg {
        font-size: 18px;

      }
    }
    .increment {
      background: ${theme.kroger.productAddToCartBg};
      width: 40px;
      height: 40px;
      svg {
        font-size: 18px;
      }
    }
  }

  & > button {
    width: 100%;
  }
`;
