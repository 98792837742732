import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { FilterModalCommonStyles } from "../commonStyles";
import { useAppContext } from "../../../context";
import { actions } from "../../../context/actions";
import {
  FormControl,
  OutlinedInput,
  IconButton,
  useTheme,
  InputAdornment,
} from "@mui/material";
import { theme } from "../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export const PriceRangeFilter = ({
  buttonsOff,
  productsBeforeFiltering,
  header,
  handleClose,
}: any) => {
  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();
  const muiTheme = useTheme();
  const priceFilter = searchFilters.find(
    (filter: any) => filter.name === "Price"
  );
  const products = [...productsBeforeFiltering];
  products.sort((a, b) => b.RegularPrice - a.RegularPrice);

  const maxPrice = products[0]?.RegularPrice || 150;
  const minPrice = products[products.length - 1]?.RegularPrice || 0;

  const [selectedRange, setSelectedRange] = useState({
    min: priceFilter?.userSelectedPriceFilterMin || "",
    max: priceFilter?.userSelectedPriceFilterMax || "",
  });

  useEffect(() => {
    setSelectedRange({
      min: priceFilter?.userSelectedPriceFilterMin || "",
      max: priceFilter?.userSelectedPriceFilterMax || "",
    });
  }, [priceFilter]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newRange = {
      ...selectedRange,
      [name]: value,
    };
    setSelectedRange(newRange);
    dispatch({
      type: actions.ADD_SEARCH_FILTER,
      payload: {
        name: "Price",
        userSelectedPriceFilterMin:
          name === "min" ? Number(value) : Number(selectedRange.min),
        userSelectedPriceFilterMax:
          name === "max" ? Number(value) : Number(selectedRange.max),
        isFiltered: true,
      },
    });
  };

  const handleClearPriceFilter = () => {
    setSelectedRange({ min: "", max: "" });
    dispatch({
      type: actions.ADD_SEARCH_FILTER,
      payload: {
        name: "Price",
        userSelectedPriceFilterMin: minPrice,
        userSelectedPriceFilterMax: maxPrice,
        isFiltered: true,
      },
    });
  };

  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff} theme={muiTheme}>
      {header && (
        <HeaderStyled>
          <span>Price</span>
          <IconButtonStyled onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButtonStyled>
        </HeaderStyled>
      )}
      <PriceRangeStyled>
        <div className="price-container">
          <div className="price-input-wrapper">
            <div className="price-label">Min Price</div>
            <FormControl variant="outlined" className="price-input">
              <OutlinedInput
                id="min-price"
                name="min"
                type="number"
                value={selectedRange.min}
                onChange={handleChange}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                inputProps={{ style: { fontSize: "16px", padding: "10px" } }}
              />
            </FormControl>
          </div>
          <div className="price-input-wrapper">
            <div className="price-label">Max Price</div>
            <FormControl variant="outlined" className="price-input">
              <OutlinedInput
                id="max-price"
                name="max"
                type="number"
                value={selectedRange.max}
                onChange={handleChange}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                inputProps={{ style: { fontSize: "16px", padding: "10px" } }}
              />
            </FormControl>
          </div>
        </div>
      </PriceRangeStyled>
    </FilterModalCommonStyles>
  );
};

const PriceRangeStyled = styled.div`
  position: relative;
  padding-bottom: 10px;
  display: flex;
  padding: 0 10px;
  flex-direction: column;

  .price-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .price-input-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 10px;
    font-size: 24px;

    &:last-of-type {
      margin-right: 0;
    }

    .price-label {
      font-size: 14px;
      margin: 5px;
    }
  }

  .price-input {
    flex: 1;
    font-size: 14px;
  }
`;

export const IconButtonStyled = styled(IconButton)`
  & > svg {
    color: ${theme.kroger.greyDark};
  }
`;

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid ${theme.kroger.grey};
`;

export const ButtonsContainer = styled.div`
  background-color: #fff;
  padding: 16px;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 70px;
  z-index: 1000;
`;
