import { BsThreeDotsVertical } from "react-icons/bs";
import { LuMenu } from "react-icons/lu";
import { useTheme } from "@mui/material";
import styled from "@emotion/styled";

export const Menu = ({ iconSize }: { iconSize: number }) => {
  const currentPath = window.location.pathname;
  const cartPath = currentPath.includes("cart");

  const theme = useTheme();

  return (
    <CartIconContainer theme={theme}>
      {cartPath ? (
        <StyledDotsIcon iconSize={iconSize} />
      ) : (
        <StyledMenuIcon iconSize={iconSize} />
      )}
    </CartIconContainer>
  );
};

const CartIconContainer = styled.div<{ theme: any }>`
  position: relative;
  cursor: pointer;
`;

const StyledDotsIcon = styled(BsThreeDotsVertical)<{ iconSize: number }>`
  font-size: ${({ iconSize }) => `${iconSize}px`};
  margin-right: 12px;
  color: ${({ theme }: any) => theme.palette.buttonBannerBg};
`;

const StyledMenuIcon = styled(LuMenu)<{ iconSize: number }>`
  font-size: ${({ iconSize }) => `${iconSize}px`};
  margin-right: 12px;
  color: ${({ theme }: any) => theme.palette.buttonBannerBg};
`;
