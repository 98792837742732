import React from "react";

import { useAppContext } from "../../../context";
import { actions } from "../../../context/actions";
import { IProduct } from "../../../types";

export const useCategoriesFilter = ({
  productsBeforeFiltering,
}: {
  productsBeforeFiltering: IProduct[];
}) => {
  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();

  const categoryFilter = searchFilters.find(
    (filter: any) => filter.name === "Categories"
  );

  const intitialCheckedState = productsBeforeFiltering.reduce(
    (obj: any, product: IProduct) => {
      return { ...obj, [product.SubCategory]: false };
    },
    {}
  );

  const [searchBar, setSearchBarInput] = React.useState("");
  const [checked, setChecked] = React.useState<any>(
    categoryFilter?.categories || intitialCheckedState
  );

  const handleSubmit = () => {
    dispatch({
      type: actions.ADD_SEARCH_FILTER,
      payload: { name: "Categories", categories: checked },
    });

    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: { isModal: false, modalContent: null },
    });
  };

  const handleClear = () => {
    setChecked(intitialCheckedState);
    dispatch({
      type: actions.REMOVE_SEARCH_FILTER,
      payload: {
        name: "Categories",
        categories: intitialCheckedState,
      },
    });
  };

  const handleCheckboxes = (categoryName: string) => {
    const newCheckedState = {
      ...checked,
      [categoryName]: !checked[categoryName],
    };

    setChecked(newCheckedState);

    dispatch({
      type: actions.ADD_SEARCH_FILTER,
      payload: { name: "Categories", categories: newCheckedState },
    });
  };

  return {
    searchBar,
    setSearchBarInput,
    handleSubmit,
    handleClear,
    handleCheckboxes,
    checked,
  };
};
