import { PopularProducts } from "../../components/PopularProducts";
import { HomeProductsSliderButtons } from "./HomeProductsSliderButtons";
import { HomeProductsSliderHeader } from "./ProductsSlider/HomeProductsSliderHeader";

export const HomeProductsSlider = ({
  shuffledProducts,
  title,
  labels,
  header,
  subHeader,
}: {
  shuffledProducts: any;
  title?: string;
  header: string;
  subHeader: string;
  labels: any[];
}) => {
  const productsData = title?.includes("Trending")
    ? shuffledProducts
    : shuffledProducts;

  return (
    <>
      <HomeProductsSliderHeader header={header} subHeader={subHeader} />
      <HomeProductsSliderButtons labels={labels} />
      <PopularProducts products={productsData} title={title}></PopularProducts>
    </>
  );
};
